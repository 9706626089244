export enum Continent {
    AFRICA = 1,
    NORTH_AMERICA = 2,
    LATIN_AMERICA = 3,
    ASIA = 4,
    EUROPE = 5,
    PACIFIC_ISLANDS = 6,
}

export interface IContinent {
    id: number;
    illustration: string;
    title: string;
}

export const availableContinents: IContinent[] = [
    { id: Continent.NORTH_AMERICA, illustration: "mv-icons--destinations--north-america", title: "Amérique du Nord" },
    { id: Continent.LATIN_AMERICA, illustration: "mv-icons--destinations--latin-america", title: "Amérique latine" },
    { id: Continent.AFRICA, illustration: "mv-icons--destinations--africa", title: "Afrique" },
    { id: Continent.ASIA, illustration: "mv-icons--destinations--asia", title: "Asie" },
    { id: Continent.EUROPE, illustration: "mv-icons--destinations--europe", title: "Europe" },
    { id: Continent.PACIFIC_ISLANDS, illustration: "mv-icons--destinations--pacific-islands", title: "Océanie" },
];

export const isContinent = (object: { [key: string]: any }): object is IContinent =>
    availableContinents.find(
        (continent) =>
            object.id === continent.id &&
            object.title === continent.title &&
            object.illustration === continent.illustration,
    ) != null;

export const isContinentId = (id: number): id is Continent =>
    Object.keys(Continent)
        .map((key: keyof typeof Continent) => Continent[key])
        .includes(id);

export const getContinent = (continentId: Continent): IContinent | undefined =>
    availableContinents.find((continent) => continent.id === continentId);

export const isSameContinent = (c1: IContinent | null, c2: IContinent | null): boolean => {
    if (c1 == null || c2 == null) {
        return c1 === c2;
    }

    return c1.id === c2.id;
};

<div *ngIf="type === 'classic'" class="button">
    <span>{{ title }}</span>
</div>

<div *ngIf="type === 'plain'" class="button plain-button">
    <span>{{ title }}</span>
</div>

<div *ngIf="type === 'play'" class="button play-button">
    <i class=""></i>
</div>
<div *ngIf="type === 'pause'" class="button pause-button">
    <div></div>
</div>
<div *ngIf="type === 'prev'" class="button track-prev-button">
    <i class=""></i>
</div>
<div *ngIf="type === 'next'" class="button track-next-button">
    <i class=""></i>
</div>

<div *ngIf="type === 'back'" class="back-button" [class.secondary-color]="secondaryColor">
    <div class="circle"></div>
    <span [innerHTML]="title"></span>
</div>

<div *ngIf="type === 'fullscreen'" class="fullscreen">
    <div class="top left"></div>
    <div class="bottom left"></div>
    <div class="top right"></div>
    <div class="bottom right"></div>
</div>

export enum Month {
    JANUARY = 1,
    FEBRUARY = 2,
    MARCH = 3,
    APRIL = 4,
    MAY = 5,
    JUNE = 6,
    JULY = 7,
    AUGUST = 8,
    SEPTEMBER = 9,
    OCTOBER = 10,
    NOVEMBER = 11,
    DECEMBER = 12,
}

export interface IMonth {
    id: number;
    title: string;
}

export const availableMonths: IMonth[] = [
    { id: Month.JANUARY, title: "Janvier" },
    { id: Month.FEBRUARY, title: "Février" },
    { id: Month.MARCH, title: "Mars" },
    { id: Month.APRIL, title: "Avril" },
    { id: Month.MAY, title: "Mai" },
    { id: Month.JUNE, title: "Juin" },
    { id: Month.JULY, title: "Juillet" },
    { id: Month.AUGUST, title: "Août" },
    { id: Month.SEPTEMBER, title: "Septembre" },
    { id: Month.OCTOBER, title: "Octobre" },
    { id: Month.NOVEMBER, title: "Novembre" },
    { id: Month.DECEMBER, title: "Décembre" },
];

export const isMonth = (object: { [key: string]: any }): object is IMonth =>
    availableMonths.find((month) => object.id === month.id && object.title === month.title) != null;

export const isMonthId = (id: number): id is Month =>
    Object.keys(Month)
        .map((key: keyof typeof Month) => Month[key])
        .includes(id);

export const getMonth = (monthId: Month): IMonth | undefined => availableMonths.find((month) => month.id === monthId);

export const isSameMonth = (m1: IMonth | null, m2: IMonth | null): boolean => {
    if (m1 == null || m2 == null) {
        return m1 === m2;
    }

    return m1.id === m2.id;
};

export const getNextMonth = (nbMonth: number): IMonth => availableMonths[(new Date().getMonth() + nbMonth - 1) % 12];

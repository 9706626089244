import { KeyValue } from "@angular/common";

export enum Suitcase {
    S = "Un court séjour",
    M = "Un moyen séjour",
    L = "Un long séjour",
}

export interface ISuitcaseDuration {
    max?: number;
    min?: number;
}

export const suitcaseSort = (s1: KeyValue<string, string>, s2: KeyValue<string, string>): number =>
    // S - M - L is actually reverse alphabetical order
    s1.key.localeCompare(s2.key) * -1;

export const getSuitecaseDuration = (suitcase: any): ISuitcaseDuration | null => {
    let duration: ISuitcaseDuration | null = null;

    switch (suitcase.value) {
        case Suitcase.S:
            duration = { max: 10, min: 0 };
            break;
        case Suitcase.M:
            duration = { max: 14, min: 11 };
            break;
        case Suitcase.L:
            duration = { min: 14, max: 356 };
            break;
        default:
    }

    return duration;
};

import { CommonModule } from "@angular/common";
import { NgModule } from "@angular/core";

import { SwiperModule } from "ngx-swiper-wrapper";

import { SharedModule } from "@shared/shared.module";
import { PlayerDetailsComponent } from "./player-details/player-details.component";
import { PlayerComponent } from "./player/player.component";
@NgModule({
    declarations: [PlayerComponent, PlayerDetailsComponent],
    exports: [PlayerComponent, PlayerDetailsComponent],
    imports: [CommonModule, SharedModule, SwiperModule],
})
export class PlayerModule {}

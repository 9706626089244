import { HttpEvent, HttpHandler, HttpInterceptor, HttpRequest } from "@angular/common/http";
import { Injectable } from "@angular/core";

import { Observable, throwError } from "rxjs";
import { catchError } from "rxjs/operators";

@Injectable()
export class ErrorInterceptor implements HttpInterceptor {
    public intercept(req: HttpRequest<any>, next: HttpHandler): Observable<HttpEvent<any>> {
        return next.handle(req).pipe(
            // intercept the error, do some processing, then rethrow a new formatted error
            catchError((error) => {
                const formattedError = formatError(error);
                sendNotification(error, formattedError);

                return throwError(formattedError);
            }),
        );
    }
}

function formatError(error: any): string {
    if (typeof error === "string") {
        return error;
    }
    if (error instanceof Error) {
        return error.message;
    }

    if (error.error != null && error.error.message != null && typeof error.error.message === "string") {
        return error.error.message;
    }

    console.error(`ErrorInterceptor : formatError - unknown error`, error);

    return `unknown ErrorInterceptor error`;
}

// TODO : add slack / email notification
function sendNotification(error: any, formattedError: string) {
    console.error(`ErrorInterceptor : ${formattedError}`, error);
}

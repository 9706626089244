<ng-container *ngIf="mapAllowed">
    <div class="map--toggler">
        <div class="map--toggler__btn" (click)="toggleMap()">
            <span *ngIf="!isMapVisible">Carte</span>
            <span *ngIf="isMapVisible">Fermer</span>
        </div>
    </div>

    <div class="map--controls" [ngClass]="{ visible: isMapVisible }">
        <div class="map--controls__item" (click)="zoomOut()">Zoom -</div>
        <div class="map--controls__item" (click)="zoomIn()">Zoom +</div>
    </div>

    <div class="map--shortcuts" [ngClass]="{ visible: isMapVisible }">
        <ng-container *ngFor="let continent of continents">
            <div class="map--shortcuts__item" (click)="selectContinent(continent)">
                <i class="{{ continent.class }}"></i>
            </div>
        </ng-container>
    </div>

    <div class="map--container" [ngClass]="{ open: isMapVisible, loaded: initMap }">
        <ng-container *ngIf="initMap">
            <mv-map provider="mapbox" id="openMap" markers="[]"></mv-map>
        </ng-container>
    </div>
</ng-container>

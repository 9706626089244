import { Injectable } from "@angular/core";

import { ITagGetDto } from "@dtos";
import { ITag } from "@models";

@Injectable()
export class TagMapper {
    public mapGetTag = (tagDto: ITagGetDto): ITag => {
        return { ...tagDto };
    };
}

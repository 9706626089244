export enum EnvironmentType {
    PROD = "prod",
    PREPROD = "preprod",
    TESTING = "testing",
    DEV = "dev",
}

export interface IEnvironment {
    brodcastHost: string;
    driverInatictivityTime: number;
    inatictivityTime: number;
    isDriverActivated: boolean;
    mapServerUrl: string;
    mediaAssetsPath: string;
    mediaAssetsPathOptimized: string;
    moustacheApiUrl: string;
    production: boolean;
    remoteRoute: string;
    siteAssetsUrl: string;
    streamingVideoPath: string;
    type: EnvironmentType;
}

import { Injectable } from "@angular/core";
import { CanActivate, Router, UrlTree } from "@angular/router";
import { Observable } from "rxjs";
import { map } from "rxjs/operators";

import { AppRoutes } from "@app/routes";
import { SuitcaseService } from "@models";

@Injectable({ providedIn: "root" })
export class DiscoverGuard implements CanActivate {
    public constructor(
        private readonly suitcaseService: SuitcaseService,
        private readonly router: Router,
    ) {}

    public canActivate(): Observable<boolean | UrlTree> {
        return this.suitcaseService.suitcase$.pipe(
            map((suitcase) => (suitcase != null ? true : this.router.createUrlTree([AppRoutes.HOME]))),
        );
    }
}

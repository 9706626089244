import { Injectable } from "@angular/core";
import { BehaviorSubject, Observable } from "rxjs";

import { getDeviceFromDimensions, IDevice, initialDevice, isSameDevice } from "@models/device/device";

@Injectable({ providedIn: "root" })
export class DeviceService {
    public readonly device$: Observable<IDevice>;
    private readonly deviceSource = new BehaviorSubject<IDevice>(initialDevice);

    public constructor() {
        this.device$ = this.deviceSource.asObservable();
    }

    public setDevice(dimensions: { height: number; width: number }) {
        const device = getDeviceFromDimensions(dimensions);
        if (!isSameDevice(device, this.deviceSource.value)) {
            this.deviceSource.next(device);
        }
    }

    public getDeviceHeight() {
        return this.deviceSource.value.height;
    }

    public getDeviceWidth() {
        return this.deviceSource.value.width;
    }
}

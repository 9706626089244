import { Injectable, RendererFactory2 } from "@angular/core";

import { BehaviorSubject, Observable } from "rxjs";

import { PlayerService } from "@app/core/player/player.service";
import { environment } from "@environment";
import { SessionService } from "@models/session/session.service";
import { NavigationService } from "../navigation/navigation.service";
import { TourService } from "../tour/tour.service";
import { VideoService } from "../video/video.service";
import { IDriverCursorDirection, IDriverStep, IDriverStepGroup } from "./driver-step";

@Injectable({ providedIn: "root" })
export class DriverService extends SessionService {
    public static driverReference = {
        DISCOVER: "discover",
        DISCOVER_TOUR: "discover-tour",
        HOME: "home",
        MULTIMEDIA: "multimedia",
    };
    public showDriver$: Observable<boolean>;

    public activeStepGroup: IDriverStepGroup | null = null;
    public activeStep: IDriverStep | null = null;
    public activeStepIndex: number = 0;

    public stepsGroup: IDriverStepGroup[] = [];
    private readonly showDriverSource = new BehaviorSubject<boolean>(false);

    public constructor(
        public readonly rendererFactory2: RendererFactory2,
        private readonly navigationService: NavigationService,
        private readonly playerService: PlayerService,
        private readonly videoService: VideoService,
        private readonly tourService: TourService,
    ) {
        super(rendererFactory2);
        this.origin = `driver`;
        this.showDriver$ = this.showDriverSource.asObservable();

        this.buildDriverSteps();

        // subscribe to player state play or stop video
        this.playerService.playerObsState$.subscribe((played) => {
            // if video is playing
            if (played) {
                this.blockBehavior = true;
            } else {
                this.blockBehavior = false;

                // if no session on going, just start one
                if (!this.isOnGoing && this.sessionNumber > 0) {
                    this.reset();
                }
            }
        });
    }

    // init from login page
    public initDriver() {
        // init driver only if active in settings
        if (environment.isDriverActivated) {
            this.startSession(environment.driverInatictivityTime);
            this.getEventSessionEnd().subscribe(() => {
                if (!this.blockBehavior) {
                    this.manageDriver();
                }
            });
        }
    }

    public async clear() {
        this.resetDriverSteps();
        this.reset();
    }

    public async reset() {
        this.activeStepIndex = 0;
        this.activeStepGroup = null;
        this.activeStep = null;
        this.showDriverSource.next(false);
        this.startSession();
    }

    public manageDriver() {
        let reference = this.navigationService.getCurrentSection();

        const tour = this.tourService.getCurrentTour();
        if (tour) {
            reference = DriverService.driverReference.DISCOVER_TOUR;
        }

        // retrerive the current driver step
        this.retreiveDriverStepGroupByReference(reference);

        if (this.activeStepGroup) {
            this.retreiveDriverActiveStep();
            // close player if it's open just in case
            this.playerService.hide();
            this.showDriverSource.next(true);
        } else {
            this.reset();
        }
    }

    public interactAction() {
        if (this.activeStep) {
            if (this.activeStep.actionEnd) {
                this.activeStep.actionEnd();
            }
        }

        this.activeStepIndex++;

        if (this.activeStepGroup && this.activeStepIndex <= this.activeStepGroup.steps.length) {
            this.retreiveDriverActiveStep();

            if (!this.activeStep) {
                this.reset();
            }
        } else {
            this.reset();
        }
    }

    protected retreiveDriverStepGroupByReference(reference: string) {
        this.activeStepGroup = null;

        this.stepsGroup.forEach((stepGroup: IDriverStepGroup) => {
            if (stepGroup.reference === reference && !stepGroup.displayed) {
                this.activeStepGroup = stepGroup;
                this.activeStepGroup.displayed = true;
            }
        });
    }

    protected retreiveDriverActiveStep() {
        this.activeStep = null;

        if (this.activeStepGroup) {
            this.activeStep = this.activeStepGroup.steps[this.activeStepIndex];
        }

        if (this.activeStep) {
            if (this.activeStep.actionStart) {
                this.activeStep.actionStart();
            }
        }
    }

    protected resetDriverSteps() {
        // for each stepsGroup set displayed to false
        this.stepsGroup.forEach((stepGroup: IDriverStepGroup) => {
            stepGroup.displayed = false;
        });
    }

    protected buildDriverSteps() {
        // Home
        this.buildDriverStepsHome();

        // Multimedia
        this.buildDriverStepsMultimedia();

        // Discover
        this.buildDriverStepsDiscover();

        // Tour
        this.buildDriverStepsTour();
    }

    protected buildDriverStepsHome() {
        const stepOne: IDriverStep = {
            spotlight: {
                diameter: `20vh`,
                x: `25.5vw`,
                y: `39.5vh`,
            },
            cursor: {
                animated: true,
                color: `rgba(255, 255, 255, 0.8)`,
                direction: IDriverCursorDirection.HORIZONTAL,
                from: `0`,
                rotation: `0deg`,
                speed: `6s`,
                to: `40vh`,
                x: `13vw`,
                y: `62vh`,
            },
            toast: {
                color: `rgba(255, 255, 255, 1)`,
                duration: null,
                text: "Découvrez nos voyages et choisissez la durée de votre séjour",
                talignment: `center`,
                width: `45vw`,
                x: `5vw`,
                y: `70vh`,
            },
        };

        const stepTwo: IDriverStep = {
            spotlight: {
                diameter: `30vh`,
                x: `66.5vw`,
                y: `45vh`,
            },
            cursor: {
                animated: true,
                color: `rgba(255, 255, 255, 0.6)`,
                direction: IDriverCursorDirection.HORIZONTAL,
                from: `0`,
                rotation: `0deg`,
                speed: `6s`,
                to: `60vh`,
                x: `47vw`,
                y: `77vh`,
            },
            toast: {
                color: `rgba(255, 255, 255, 1)`,
                duration: null,
                text: "Inspirez vous en regardant notre sélection de vidéos",
                talignment: `center`,
                width: `45vw`,
                x: `44.5vw`,
                y: `85vh`,
            },
        };

        this.stepsGroup.push({
            displayed: false,
            reference: DriverService.driverReference.HOME,
            steps: [stepOne, stepTwo],
        });
    }

    protected buildDriverStepsMultimedia() {
        const stepOne: IDriverStep = {
            spotlight: {
                diameter: `20vh`,
                x: `28.5vw`,
                y: `42vh`,
            },
            cursor: {
                animated: true,
                color: `rgba(255, 255, 255, 0.8)`,
                direction: IDriverCursorDirection.HORIZONTAL,
                from: `0`,
                rotation: `0deg`,
                speed: `6s`,
                to: `48vh`,
                x: `13vw`,
                y: `64vh`,
            },
            toast: {
                color: `rgba(255, 255, 255, 1)`,
                duration: null,
                text: "Sélectionnez une vidéo pour lancer la lecture",
                talignment: `center`,
                width: `45vw`,
                x: `6vw`,
                y: `70vh`,
            },
        };

        const stepTwo: IDriverStep = {
            spotlight: {
                diameter: `12vh`,
                x: `31vw`,
                y: `20vh`,
            },
            cursor: {
                animated: true,
                color: `rgba(255, 255, 255, 0.8)`,
                direction: IDriverCursorDirection.HORIZONTAL,
                from: `0`,
                rotation: `0deg`,
                speed: `6s`,
                to: `25vh`,
                x: `23vw`,
                y: `34vh`,
            },
            toast: {
                color: `rgba(255, 255, 255, 1)`,
                duration: null,
                text: "Vous pouvez choisir une thématique et visionner la vidéo de votre choix",
                talignment: `center`,
                width: `40vw`,
                x: `12vw`,
                y: `42vh`,
            },
        };

        const stepThree: IDriverStep = {
            actionStart: () => {
                // get first video
                const video: any = document.getElementsByClassName("video-thumbnail-trigger")[0];
                // click on it to open the player
                video.click();
            },
            spotlight: {
                diameter: `15vh`,
                x: `14vw`,
                y: `35vh`,
            },
            cursor: {
                animated: true,
                color: `rgba(255, 255, 255, 0.8)`,
                direction: IDriverCursorDirection.VERTICAL,
                from: `0`,
                rotation: `-90deg`,
                speed: `6s`,
                to: `75vh`,
                x: `24vw`,
                y: `15vh`,
            },
            toast: {
                color: `rgba(255, 255, 255, 1)`,
                duration: null,
                text: "Utilisez la playlist pour naviguer entre les vidéos",
                talignment: `left`,
                width: `40vw`,
                x: `28vw`,
                y: `32vh`,
            },
        };

        const stepFour: IDriverStep = {
            actionStart: () => {
                // open the player commands
                this.playerService.toggleCommands();
                setTimeout(() => {
                    this.playerService.pauseVideo();
                }, 300);
            },
            actionEnd: () => {
                this.videoService.clear();
            },
            spotlight: {
                diameter: `15vh`,
                x: `33.5vw`,
                y: `40vh`,
            },
            cursor: {
                animated: true,
                color: `rgba(255, 255, 255, 0.8)`,
                direction: IDriverCursorDirection.HORIZONTAL,
                from: `0`,
                rotation: `0`,
                speed: `6s`,
                to: `24.5vh`,
                x: `25vw`,
                y: `58vh`,
            },
            toast: {
                color: `rgba(255, 255, 255, 1)`,
                duration: null,
                text: "Contrôlez la lecture des vidéos",
                talignment: `left`,
                width: `40vw`,
                x: `20vw`,
                y: `65vh`,
            },
        };

        this.stepsGroup.push({
            displayed: false,
            reference: DriverService.driverReference.MULTIMEDIA,
            steps: [stepOne, stepTwo, stepThree, stepFour],
        });
    }

    protected buildDriverStepsDiscover() {
        const stepOne: IDriverStep = {
            spotlight: {
                diameter: `35vh`,
                x: `40vw`,
                y: `50vh`,
            },
            cursor: {
                animated: true,
                color: `rgba(255, 255, 255, 0.8)`,
                direction: IDriverCursorDirection.VERTICAL,
                from: `0`,
                rotation: `90deg`,
                speed: `6s`,
                to: `50vh`,
                x: `12vw`,
                y: `25vh`,
            },
            toast: {
                color: `rgba(255, 255, 255, 1)`,
                duration: null,
                text: "Choisissez les thématiques de votre voyage",
                talignment: `center`,
                width: `20vw`,
                x: `4vw`,
                y: `6vh`,
            },
        };

        const stepTwo: IDriverStep = {
            spotlight: {
                diameter: `10vh`,
                x: `56.25vw`,
                y: `22vh`,
            },
            cursor: {
                animated: true,
                color: `rgba(255, 255, 255, 0.8)`,
                direction: IDriverCursorDirection.HORIZONTAL,
                from: `0`,
                rotation: `0`,
                speed: `6s`,
                to: `15vw`,
                x: `48vw`,
                y: `33vh`,
            },
            toast: {
                color: `rgba(255, 255, 255, 1)`,
                duration: null,
                text: "Choisissez la zone géographique",
                talignment: `center`,
                width: `22vw`,
                x: `45.5vw`,
                y: `40vh`,
            },
        };

        const stepThree: IDriverStep = {
            spotlight: {
                diameter: `12vh`,
                x: `72.5vw`,
                y: `57vh`,
            },
            cursor: {
                animated: true,
                color: `rgba(255, 255, 255, 0.8)`,
                direction: IDriverCursorDirection.VERTICAL,
                from: `0`,
                rotation: `90deg`,
                speed: `6s`,
                to: `25vh`,
                x: `60vw`,
                y: `42vh`,
            },
            toast: {
                color: `rgba(255, 255, 255, 1)`,
                duration: null,
                text: "Consulter les propositions de voyage",
                talignment: `center`,
                width: `20vw`,
                x: `37.5vw`,
                y: `50vh`,
            },
        };

        this.stepsGroup.push({
            displayed: false,
            reference: DriverService.driverReference.DISCOVER,
            steps: [stepOne, stepTwo, stepThree],
        });
    }

    protected buildDriverStepsTour() {
        const stepOne: IDriverStep = {
            spotlight: {
                diameter: `20vh`,
                x: `29vw`,
                y: `53vh`,
            },
            cursor: {
                animated: true,
                color: `rgba(255, 255, 255, 0.8)`,
                direction: IDriverCursorDirection.VERTICAL,
                from: `0`,
                rotation: `90deg`,
                speed: `6s`,
                to: `40vh`,
                x: `11vw`,
                y: `32vh`,
            },
            toast: {
                color: `rgba(255, 255, 255, 1)`,
                duration: null,
                text: "Consulter les différentes composantes de votre voyage",
                talignment: `left`,
                width: `25vw`,
                x: `6vw`,
                y: `13vh`,
            },
        };

        const stepTwo: IDriverStep = {
            actionStart: () => {
                const tab = document.getElementsByTagName("mv-tour")[0];
                if (tab.shadowRoot) {
                    tab.shadowRoot.getElementById("tab-itinerary")?.click();
                }
            },
            spotlight: {
                diameter: `40vh`,
                x: `48vw`,
                y: `52vh`,
            },
            cursor: {
                animated: true,
                color: `rgba(255, 255, 255, 0.8)`,
                direction: IDriverCursorDirection.VERTICAL,
                from: `0`,
                rotation: `-90deg`,
                speed: `6s`,
                to: `40vh`,
                x: `72vw`,
                y: `31vh`,
            },
            toast: {
                color: `rgba(255, 255, 255, 1)`,
                duration: null,
                text: "Le détail de votre itinéraire",
                talignment: `left`,
                width: `25vw`,
                x: `72vw`,
                y: `22vh`,
            },
        };

        this.stepsGroup.push({
            displayed: false,
            reference: DriverService.driverReference.DISCOVER_TOUR,
            steps: [stepOne, stepTwo],
        });
    }
}

<ng-container *ngIf="playerService.playerDisplay$ | async as state">
    <div
        class="player"
        [ngClass]="{
            'player--show': state,
            'player--hide': !state,
            'fullscreen--on': playerService.fullscreen,
            'fullscreen--off': !playerService.fullscreen
        }"
    >
        <div class="player__content__main">
            <div class="player__content__main--left">
                <div class="controller">
                    <walrus-button
                        class="back"
                        type="back"
                        title="{{ backBtnTitle }}"
                        (click)="close()"
                    ></walrus-button>
                </div>
                <div class="playlist">
                    <ng-container *ngIf="playerService.relatedVideo$ | async as videos">
                        <swiper id="swiperPlaylist" [config]="swiperOptions">
                            <div
                                id="{{ video.id }}"
                                class="playlist--item swiper-slide"
                                *ngFor="let video of videos; trackBy: trackById"
                            >
                                <walrus-video
                                    [item]="video"
                                    [current$]="playerService.currentVideo$"
                                    size="small"
                                    (click)="playVideo(video)"
                                >
                                </walrus-video>
                            </div>
                        </swiper>
                    </ng-container>
                </div>
            </div>
            <div class="player__content__main--right">
                <fumanbrush-player-details></fumanbrush-player-details>
                <div *ngIf="playerService.screensaver" class="screensaver--layer" (click)="clickScreensaver()"></div>
                <video id="mainvideo" class="video-js"></video>
                <div
                    class="played__video--fabcommand"
                    [ngClass]="{
                        opened: (playerService.openCommand$ | async) === true,
                        hide: (playerService.hideCommand$ | async) === true,
                        show: (playerService.hideCommand$ | async) === false,
                        'fullscreen--on': playerService.fullscreen,
                        screensaver: playerService.screensaver
                    }"
                >
                    <div class="played__video--fabcommand__button main" (click)="playerService.toggleCommands()">
                        <div class="icon">
                            <i class="mv-icons--simple--plus_sign"></i>
                        </div>
                    </div>

                    <div
                        class="played__video--fabcommand__button played__video--fabcommand__button--item first"
                        (click)="playPrev()"
                    >
                        <div class="icon">
                            <i class="mv-icons--player--previous"></i>
                        </div>
                    </div>
                    <div
                        class="played__video--fabcommand__button played__video--fabcommand__button--item second"
                        (click)="togglePlay()"
                    >
                        <div class="icon">
                            <i
                                [ngClass]="{
                                    'mv-icons--player--play': playerService.playPauseBtnTitle === 'play',
                                    'mv-icons--player--pause': playerService.playPauseBtnTitle === 'pause'
                                }"
                            ></i>
                        </div>
                    </div>
                    <div
                        class="played__video--fabcommand__button played__video--fabcommand__button--item third"
                        (click)="toggleFullscreen()"
                    >
                        <div class="icon">
                            <i
                                [ngClass]="{
                                    'mv-icons--player--fullscreen--on': playerService.fullscreen === false,
                                    'mv-icons--player--fullscreen--off': playerService.fullscreen === true
                                }"
                            ></i>
                        </div>
                    </div>
                    <div
                        class="played__video--fabcommand__button played__video--fabcommand__button--item fourth"
                        (click)="playNext()"
                    >
                        <div class="icon">
                            <i class="mv-icons--player--next"></i>
                        </div>
                    </div>
                </div>
                <div class="played__video--command">
                    <div
                        *ngIf="playerService.currentProgressBar$ | async as progress"
                        class="progression"
                        [ngStyle]="{ width: progress + '%' }"
                    ></div>
                    <div class="progression--time">
                        <div
                            *ngIf="playerService.currentRemainingTime$ | async as rTime"
                            class="progression--time__remaining"
                        >
                            {{ rTime }}
                        </div>
                        <div
                            *ngIf="playerService.currentDurationTime$ | async as dTime"
                            class="progression--time__duration"
                        >
                            {{ dTime }}
                        </div>
                    </div>
                    <ng-container *ngIf="currentVideo != null">
                        <div class="played__video--details">
                            <div class="title">
                                {{ currentVideo.title }}
                            </div>
                            <div class="description">
                                {{ currentVideo.description }}
                            </div>
                        </div>
                    </ng-container>
                </div>
            </div>
        </div>
    </div>
</ng-container>

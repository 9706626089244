import { Component, ViewChild } from "@angular/core";

import { NavigationEnd, Router } from "@angular/router";

@Component({
    selector: "walrus-map",
    styleUrls: ["./map.component.scss"],
    templateUrl: "./map.component.html",
})
export class MapComponent {
    @ViewChild("map", { static: true }) public map: any;

    public _map: any = null;
    public initMap = false;
    public isMapVisible = false;
    public mapAllowed: boolean = true;

    public continents = [
        { id: 1, name: `Amérique du Nord`, class: `mv-icons--destinations--north-america`, coords: [-100, 40] },
        { id: 2, name: `Amérique Latine`, class: `mv-icons--destinations--latin-america`, coords: [-60, -20] },
        { id: 3, name: `Afrique`, class: `mv-icons--destinations--africa`, coords: [20, 0] },
        { id: 4, name: `Asie`, class: `mv-icons--destinations--asia`, coords: [100, 40] },
        { id: 5, name: `Europe`, class: `mv-icons--destinations--europe`, coords: [10, 50] },
        { id: 6, name: `Océanie`, class: `mv-icons--destinations--pacific-islands`, coords: [150, -20] },
    ];

    public constructor(private readonly router: Router) {
        this.subscribeRouterUpdate();
    }

    public subscribeRouterUpdate() {
        this.router.events.subscribe((event) => {
            if (event instanceof NavigationEnd) {
                // if event.url contains remote then mapAllowed = false
                if (event.url.includes("remote")) {
                    this.mapAllowed = false;
                } else {
                    this.mapAllowed = true;
                }
            }
        });
    }

    public toggleMap(): void {
        this.isMapVisible = !this.isMapVisible;
        this.resizeMap();
    }

    public resizeMap() {
        if (this.isMapVisible && !this.initMap) {
            this.initMap = true;
            setTimeout(() => {
                const mapContainer: any = document.querySelector("#openMap");
                const mapMapbox: any = mapContainer.shadowRoot.querySelector("mv-map-mapbox");

                mapMapbox.style.display = "block";
                mapMapbox.style.height = "100%";
                mapMapbox.shadowRoot.querySelector("#mapElement").style.height = "100%";

                this.initizializeMap(mapContainer, mapMapbox);
            }, 200);
        }
    }

    public zoomOut() {
        this._map.zoomOut();
        console.log(this._map.getZoom());
    }

    public zoomIn() {
        this._map.zoomIn();
        console.log(this._map.getZoom());
    }

    public setCenter(coords: any) {
        this._map.setCenter(coords);
    }

    public setFlyTo(coords: any) {
        this._map.flyTo({
            center: coords,
            zoom: 5, // You can adjust the zoom level as needed
            speed: 1, // Speed of the animation
            curve: 1, // Curvature of the flight path
            essential: true, // Indicates if this animation is essential. It disables navigation during animation if true.
        });
    }

    public async selectContinent(continent: any) {
        console.log(`selectContinent`, continent);
        this.setFlyTo(continent.coords);
    }

    protected initizializeMap(mapContainer: any, mapMapbox: any) {
        mapContainer.shadowRoot.querySelectorAll("mv-map-mapbox").forEach((map: any) => {
            mapMapbox.addEventListener("mapIsReady", (e: any) => {
                this._map = e.detail._map;
            });
        });
    }
}

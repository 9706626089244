export enum Theme {
    CULTURE = 1,
    DETENTE = 2,
    FAMILY = 3,
    FOODING = 4,
    NATURE = 5,
    SPORT = 6,
}

export interface ITheme {
    id: number;
    illustration: string;
    isActive: boolean;
    title: string;
}

export const availableThemes: ITheme[] = [
    { id: Theme.DETENTE, illustration: "mv-icons--oqp--detente", isActive: false, title: "Détente & Bien-être" },
    { id: Theme.FOODING, illustration: "mv-icons--oqp--fooding", isActive: false, title: "Fooding & Oenologie" },
    { id: Theme.CULTURE, illustration: "mv-icons--oqp--culture", isActive: false, title: "Culture & Patrimoine" },
    { id: Theme.NATURE, illustration: "mv-icons--oqp--nature", isActive: false, title: "Nature & Vie sauvage" },
    { id: Theme.SPORT, illustration: "mv-icons--oqp--sport", isActive: false, title: "Sport & Aventure" },
    { id: Theme.FAMILY, illustration: "mv-icons--oqp--family", isActive: false, title: "Famille & Tribu" },
];

export const isTheme = (object: { [key: string]: any }): object is ITheme =>
    availableThemes.find(
        (theme) => object.id === theme.id && object.title === theme.title && object.illustration === theme.illustration,
    ) != null;

export const isThemeId = (id: number): id is Theme =>
    Object.keys(Theme)
        .map((key: keyof typeof Theme) => Theme[key])
        .includes(id);

export const getThemes = (themes: number[]): ITheme[] =>
    themes
        .filter(isThemeId)
        .map((themeId) => availableThemes.find((theme) => theme.id === themeId))
        .filter((theme): theme is ITheme => theme != null);

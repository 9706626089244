import { NgModule } from "@angular/core";
import { RouterModule, Routes } from "@angular/router";

import { DiscoverGuard } from "@core/guards/discover.guard";
import { AppRoutes } from "./routes";

const routes: Routes = [
    {
        loadChildren: () => import("./home/home.module").then((m) => m.HomeModule),
        path: AppRoutes.HOME,
    },
    {
        loadChildren: () => import("./multimedia/multimedia.module").then((m) => m.MultimediaModule),
        path: AppRoutes.MULTIMDEIA,
    },
    {
        loadChildren: () => import("./core/remote/remote.module").then((m) => m.RemoteModule),
        path: AppRoutes.REMOTE,
    },
    {
        canActivate: [DiscoverGuard],
        loadChildren: () => import("./discover/discover.module").then((m) => m.DiscoverModule),
        path: AppRoutes.DISCOVER,
    },
    {
        path: "**",
        redirectTo: AppRoutes.HOME,
    },
];

@NgModule({
    exports: [RouterModule],
    imports: [RouterModule.forRoot(routes, { useHash: true })],
})
export class AppRoutingModule {}

import { Injectable, OnDestroy } from "@angular/core";
import { BehaviorSubject, interval, Observable, Subscription } from "rxjs";
import { takeWhile } from "rxjs/operators";

import { environment } from "@environment";

import { IVideo } from "@models/video/video";

import videojs from "video.js";

export const playerOptions = {
    autoplay: false,
    controls: false,
    fluid: true,
    muted: false,
    play: true,
    preload: "none",
    responsive: false,
    sources: [],
};

const BTN_STATE = {
    PAUSE: "pause",
    PLAY: "play",
};

const VIDEO_FORMAT = "?format=4k";

@Injectable({ providedIn: "root" })
export class PlayerService implements OnDestroy {
    public player: videojs.Player;

    public playerState = false;

    public playerObsState$: Observable<boolean>;
    private readonly playerStateSource = new BehaviorSubject<boolean>(false);

    public currentVideo$: Observable<IVideo | null>;
    public currentVideoPlaylist$: Observable<number>;
    public relatedVideo$: Observable<IVideo[]>;

    public fullscreen = false;

    public screensaver = false;
    public actionScreensaver$: Observable<boolean>;

    public currentDurationTime$: Observable<string>;
    public currentRemainingTime$: Observable<string>;
    public currentProgressBar$: Observable<number>;

    public playPauseBtnTitle = BTN_STATE.PLAY;

    public playerDisplay$: Observable<boolean>;

    public hideCommand$: Observable<boolean>;
    public openCommand$: Observable<boolean>;
    public showDetails$: Observable<boolean>;

    private lastInteraction: Date = new Date();
    private commandsLive = false;

    private readonly currentVideoSource = new BehaviorSubject<IVideo | null>(null);
    private readonly currentVideoPlaylistSource = new BehaviorSubject<number>(0);
    private readonly currentDurationTimeSource = new BehaviorSubject<string>("0:00");
    private readonly currentRemainingTimeSource = new BehaviorSubject<string>("0:00");
    private readonly currentProgressBarSource = new BehaviorSubject<number>(0);
    private readonly hideCommandSource = new BehaviorSubject<boolean>(false);
    private readonly openCommandSource = new BehaviorSubject<boolean>(false);
    private readonly showDetailsSource = new BehaviorSubject<boolean>(false);

    private readonly actionScreensaverSource = new BehaviorSubject<boolean>(false);

    private relatedVideosArray: IVideo[];
    private readonly relatedVideoSource = new BehaviorSubject<IVideo[]>([]);
    private readonly mediaPath = `${environment.moustacheApiUrl}/${environment.streamingVideoPath}/`;
    private readonly playerDisplaySource = new BehaviorSubject<boolean>(false);

    private playlistSize: number = 0;
    private playlistPosition: number = 0;

    private readonly commandsHidePeriod = 0.4 * 60 * 1000;

    private readonly subscriptions = new Subscription();

    public constructor() {
        this.playerObsState$ = this.playerStateSource.asObservable();
        this.playerDisplay$ = this.playerDisplaySource.asObservable();

        this.currentVideo$ = this.currentVideoSource.asObservable();
        this.currentVideoPlaylist$ = this.currentVideoPlaylistSource.asObservable();
        this.currentDurationTime$ = this.currentDurationTimeSource.asObservable();
        this.currentRemainingTime$ = this.currentRemainingTimeSource.asObservable();
        this.currentProgressBar$ = this.currentProgressBarSource.asObservable();
        this.hideCommand$ = this.hideCommandSource.asObservable();
        this.openCommand$ = this.openCommandSource.asObservable();
        this.showDetails$ = this.showDetailsSource.asObservable();
        this.relatedVideo$ = this.relatedVideoSource.asObservable();
        this.relatedVideo$.subscribe((rVideos) => (this.relatedVideosArray = rVideos));
        this.actionScreensaver$ = this.actionScreensaverSource.asObservable();
    }

    public initPlayer() {
        this.setCurrentVideo(null);
        this.playerState = false;
        this.playerStateSource.next(this.playerState);
        this.playPauseBtnTitle = BTN_STATE.PLAY;
    }

    public idlePoll() {
        return interval(1000).pipe(
            takeWhile(() => {
                // if timer reach show screensaver
                if (new Date().getTime() - this.lastInteraction.getTime() >= this.commandsHidePeriod) {
                    this.commandsLive = false;
                    this.autoHideCommands();
                } else {
                    this.commandsLive = true;
                    this.hideCommandSource.next(false);
                }

                return new Date().getTime() - this.lastInteraction.getTime() < this.commandsHidePeriod;
            }),
        );
    }

    public toggleFullscreen() {
        this.fullscreen = !this.fullscreen;
    }

    public enableFullscreen() {
        this.fullscreen = true;
    }

    public disableFullscreen() {
        this.fullscreen = false;
    }

    public enableScreensaver() {
        this.screensaver = true;
    }

    public disableScreenSaver() {
        this.screensaver = false;
    }

    public toggleDetails() {
        const details = !this.showDetailsSource.getValue();
        details ? this.pauseVideo() : this.playVideo();
        this.showDetailsSource.next(details);
    }

    public playEnded() {
        // if screensaver end like a click
        if (this.screensaver) {
            this.screensaverClicked();
        } else {
            this.playNext();
        }
    }

    public playPrevious() {
        if (this.playlistPosition - 1 < 0) {
            this.playlistPosition = this.playlistSize;
        }

        this.playlistPosition--;
        const nextVideo = this.relatedVideosArray[this.playlistPosition];
        if (nextVideo != null) {
            this.changePlay(nextVideo);
        }
    }

    public playNext() {
        if (this.playlistPosition + 1 >= this.playlistSize) {
            this.playlistPosition = -1;
        }

        this.playlistPosition++;
        const nextVideo = this.relatedVideosArray[this.playlistPosition];
        if (nextVideo != null) {
            this.changePlay(nextVideo);
        }
    }

    public getPlaylistPosition() {
        return this.playlistPosition;
    }

    public toggleCommands() {
        this.openCommandSource.next(!this.openCommandSource.getValue());
    }

    public autoHideCommands() {
        if (this.playerState) {
            this.openCommandSource.next(false);
            this.hideCommandSource.next(true);
        } else {
            this.startSession();
        }
    }

    public hideCommands() {
        this.openCommandSource.next(false);
        this.hideCommandSource.next(true);
    }

    public playVideo() {
        this.playerState = true;
        this.playerStateSource.next(this.playerState);
        this.playPauseBtnTitle = BTN_STATE.PAUSE;
        this.player.play();
        this.player.volume(1);
        this.startSession();
    }

    public pauseVideo() {
        this.playerState = false;
        this.playerStateSource.next(this.playerState);
        this.playPauseBtnTitle = BTN_STATE.PLAY;
        this.player.pause();
        this.hideCommandSource.next(false);
    }

    public changePlay(video: IVideo) {
        this.pauseVideo();
        this.setCurrentDurationTime(0);
        this.setCurrentRemainingTime(0, 0);
        this.setCurrentVideo(video);
        this.player.src({ type: "video/mp4", src: `${this.mediaPath}${video.url}${VIDEO_FORMAT}` });
        const posPlaylist = this.relatedVideosArray.findIndex((rVideo: IVideo) => rVideo.id === video.id);
        this.playlistPosition = posPlaylist;

        // manual let service play the video
        this.playVideo();
    }

    public setCurrentVideo(video: IVideo | null) {
        this.currentVideoSource.next(video);
        this.currentVideoPlaylistSource.next(this.playlistPosition);
    }

    public setCurrentDurationTime(time: number) {
        this.currentDurationTimeSource.next(this.fancyTimeFormat(time));
    }

    public setCurrentRemainingTime(rTime: number, dTime: number) {
        this.currentRemainingTimeSource.next(this.fancyTimeFormat(rTime));
        this.currentProgressBarSource.next((rTime * 100) / dTime);
    }

    public setRelatedVideos(videos: IVideo[]) {
        this.relatedVideoSource.next([...videos]);
        this.playlistSize = videos.length;
    }

    public show(video: IVideo, relatedVideos?: IVideo[]) {
        /* tslint:disable */
        // this action is needed to call the good context in player callback
        const service = this;
        /* tslint:enable */

        this.setCurrentVideo(video);
        if (relatedVideos) {
            this.setRelatedVideos(relatedVideos);
        }
        this.playerDisplaySource.next(true);
        // wait a second for DOM change
        setTimeout(() => {
            // instantiate Video.js
            this.player = videojs(document.getElementById("mainvideo"), playerOptions);
            this.player.src({ type: "video/mp4", src: `${this.mediaPath}${video.url}${VIDEO_FORMAT}` });
            this.player.ready(() => {
                // manual let service play the video
                service.playVideo();
                // when it's finish reset player button state
                service.player.on("ended", function () {
                    service.playEnded();
                });

                service.player.on("loadedmetadata", function () {
                    service.setCurrentDurationTime(service.player.duration());
                });

                service.player.on("timeupdate", function () {
                    service.setCurrentRemainingTime(service.player.currentTime(), service.player.duration());
                });

                service.player.on("touchstart", function () {
                    service.startSession();
                });
                service.player.on("click", function () {
                    service.startSession();
                });
            });
        }, 100);
    }

    // start a session and subscribe to timer
    public startSession() {
        if (!this.commandsLive) {
            this.lastInteraction = new Date();
            this.subscriptions.add(this.idlePoll().subscribe());
        }
    }

    public hide() {
        // hide the modal
        this.playerDisplaySource.next(false);

        // hide details
        this.showDetailsSource.next(false);

        // close commands
        this.openCommandSource.next(false);

        this.disableFullscreen();

        this.disableScreenSaver();

        // destroy player
        if (this.player != null) {
            this.player.dispose();
        }
    }

    public screensaverClicked() {
        this.actionScreensaverSource.next(true);
    }

    public ngOnDestroy() {
        this.subscriptions.unsubscribe();
    }

    protected fancyTimeFormat(duration: number) {
        // Hours, minutes and seconds
        // const hrs = Math.floor(duration / 3600);
        const mins = Math.floor((duration % 3600) / 60);
        const secs = Math.floor(duration % 60);

        // if (hrs > 0) {
        //     ret += "" + hrs + ":" + (mins < 10 ? "0" : "");
        // }

        return `${mins}:${secs < 10 ? "0" : ""}${secs}`;
    }
}

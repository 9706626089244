import { Component, TrackByFunction, ViewChild } from "@angular/core";

import { SwiperComponent, SwiperDirective } from "ngx-swiper-wrapper";

import { PlayerService } from "../player.service";

import { IVideo } from "@models";

@Component({
    selector: "walrus-player",
    styleUrls: ["./player.component.scss"],
    templateUrl: "./player.component.html",
})
export class PlayerComponent {
    public currentVideo: IVideo | null;

    public backBtnTitle = `Retour`;

    @ViewChild(SwiperComponent, { static: false }) public sliderComponentRef?: SwiperComponent;
    @ViewChild(SwiperDirective, { static: false }) public sliderDirectiveRef?: SwiperDirective;

    public swiperOptions = {
        a11y: true,
        centeredSlides: false,
        direction: "vertical",
        freeMode: true,
        freeModeMinimumVelocity: 0.08,
        keyboard: true,
        loop: false,
        mousewheel: true,
        navigation: false,
        pagination: false,
        scrollbar: false,
        slidesPerView: 4.125,
    };

    public constructor(public readonly playerService: PlayerService) {
        this.playerService.currentVideo$.subscribe((video) => {
            this.currentVideo = video;
            if (this.currentVideo != null) {
                const currentPlayedVideo = document.getElementById(`${this.currentVideo.id}`);
                if (currentPlayedVideo != null) {
                    currentPlayedVideo.scrollIntoView({ behavior: "smooth", block: "start", inline: "start" });
                }
            }
        });

        this.playerService.currentVideoPlaylist$.subscribe((playlistIndex) => {
            if (this.sliderComponentRef) {
                if (this.sliderComponentRef.directiveRef) {
                    this.sliderComponentRef.directiveRef.setIndex(playlistIndex);
                }
            }
        });
    }

    public togglePlay() {
        if (this.playerService.playerState) {
            this.playerService.pauseVideo();
        } else {
            this.playerService.playVideo();
        }
    }

    public toggleFullscreen() {
        this.playerService.toggleFullscreen();
    }

    public toggleDetails() {
        this.playerService.toggleDetails();
    }

    public playPrev() {
        this.playerService.playPrevious();
    }

    public playVideo(video: IVideo) {
        this.playerService.changePlay(video);
    }

    public playNext() {
        this.playerService.playNext();
    }

    public trackById: TrackByFunction<IVideo> = (index: number, video: IVideo): any => {
        return video.id;
    };

    public close() {
        this.playerService.hide();
    }

    clickScreensaver(): void {
        this.playerService.screensaverClicked();
    }
}

import { ITourActivityGetDto, ITourHotelGetDto, ITourProductGetDto, ITourStepGetDto } from "@dtos";
import { IInclusion } from "@ui/ui-included-lines/src/inclusion";
import { ITourActivity, ITourHotel, ITourStep, ITourProduct } from "@ui/ui-tour/src/tour-product";

export class MoustacheTourMapper {
    public constructor(private readonly assetsUrl: string) {}

    public mapGetTourProduct = (tourDto: ITourProductGetDto): ITourProduct => {
        return {
            activities: tourDto.activities.map(this.mapActivity),
            averageRank: tourDto.averageRank || null,
            country: tourDto.country,
            goodToKnow: tourDto.goodToKnow,
            hotels: tourDto.hotels.map(this.mapHotel),
            id: tourDto.id,
            included: tourDto.included.map(this.mapIncluded),
            nbDays: tourDto.nbDays,
            nbNights: tourDto.nbNights,
            notIncluded: tourDto.notIncluded.map(this.mapIncluded),
            places: tourDto.places,
            steps: tourDto.steps.map(this.mapStep),
            strongpoints: tourDto.strongpoints,
            title: tourDto.title,
        };
    };

    public mapImage = (imageUrl: string): string => `${this.assetsUrl}${imageUrl}`;

    public mapActivity = (dto: ITourActivityGetDto): ITourActivity => ({
        description: dto.description,
        images: [this.mapImage(dto.imageUrl)],
        places: dto.places,
        title: dto.title,
    });

    public mapHotel = (dto: ITourHotelGetDto): ITourHotel => ({
        description: dto.description,
        images: [this.mapImage(dto.imageUrl)],
        places: dto.places,
        title: dto.title,
    });

    public mapStep = (dto: ITourStepGetDto): ITourStep => ({
        description: dto.description,
        images: dto.imagesUrl.map(this.mapImage),
        places: dto.places,
        title: dto.title,
    });

    public mapIncluded = (dto: string): IInclusion => ({
        description: dto,
        id: 1,
    });
}

import { Injectable } from "@angular/core";
import { BehaviorSubject, Observable } from "rxjs";

import { PlayerService } from "../player/player.service";

import { NavigationEnd, Router } from "@angular/router";
import { AppRoutes } from "../../routes";

import { webSocket, WebSocketSubject, WebSocketSubjectConfig } from "rxjs/webSocket";

import { BubbleService, IdleService, NavigationService, SuitcaseService, TourService, VideoService } from "@app/models";
import { environment } from "@environment";

@Injectable({ providedIn: "root" })
export class RemoteService {
    public ws: WebSocketSubject<any>;

    public clientId$: Observable<number>;

    public activeUnivers = AppRoutes.HOME;

    public routerEventInit = false;

    public videoIsOnPlay = false;

    public tourOnDisplay = false;

    public currentTour = 0;

    public connectedRemote = 0;

    public attemptToConnect = 0;

    public mainOrigin = "walrus";

    private readonly clientIdSource = new BehaviorSubject<number>(0);

    constructor(
        public readonly videoService: VideoService,
        public playerService: PlayerService,
        private readonly router: Router,
        private readonly suitcaseService: SuitcaseService,
        private readonly bubbleService: BubbleService,
        private readonly tourService: TourService,
        private readonly navigationService: NavigationService,
        private readonly idleService: IdleService,
    ) {
        console.log("Remote service init");
        this.clientId$ = this.clientIdSource.asObservable();
    }

    public getClientId() {
        return this.clientIdSource.value;
    }

    public subscribeRouterUpdate() {
        if (!this.routerEventInit) {
            this.router.events.subscribe((event) => {
                if (event instanceof NavigationEnd) {
                    // Call your service here
                    // console.log("informed remote service");
                    const univers = this.getUnivers(event.url);
                    this.sendAction(`universChange`, univers);
                    this.currentTour = 0;
                }
            });
            this.routerEventInit = true;
        }
    }

    public subscribePlayerUpdate() {
        this.playerService.playerDisplay$.subscribe((played) => {
            // console.log("informed remote service");
            this.sendAction(`playerChange`, played);
        });
    }

    public subscribeTourDisplayUpdate() {
        this.tourService.currentTour$.subscribe((tour) => {
            // console.log("informed remote service");
            const state = tour != null ? true : false;
            // console.log(tour);
            // console.log(state);
            this.sendAction(`displayTour`, state);
        });
    }

    public getUnivers(url: string) {
        const univers = url.split("/")[1];

        switch (univers) {
            case AppRoutes.HOME:
            case AppRoutes.MULTIMDEIA:
            case AppRoutes.DISCOVER:
                return univers;
            default:
                return AppRoutes.HOME;
        }
    }

    public connect(origin: string = "guest", hostId: any = null) {
        // this.ws = webSocket(environment.brodcastHost);
        this.mainOrigin = origin;
        this.ws = webSocket({
            url: environment.brodcastHost,
            openObserver: {
                next: () => {
                    // Perform actions after connecting
                    this.send({ origin, hostId });
                },
            },
        } as WebSocketSubjectConfig<any>);

        this.ws.subscribe(
            (msg) => this.msgReceived(msg),
            (err) => this.errReceived(err),
            () => {},
        );
    }

    public sendAction(action: any, data: any = null) {
        if (this.ws) {
            this.ws.next({ action, data });
        }
    }

    public send(message: any) {
        if (this.ws) {
            this.ws.next(message);
        }
    }

    public activeUniversIsHome() {
        return this.activeUnivers === AppRoutes.HOME;
    }

    public activeUniversIsMultimedia() {
        return this.activeUnivers === AppRoutes.MULTIMDEIA;
    }

    public activeUniversIsDiscover() {
        return this.activeUnivers === AppRoutes.DISCOVER;
    }

    protected msgReceived(info: any) {
        // Called whenever there is a message from the server.
        console.log("message received");
        console.log(info);

        this.idleService.resetSession();

        // check if it's the first message from server
        if (info.firstConnect) {
            this.attemptToConnect = 0;
            this.clientIdSource.next(info.data);

            if (info.origin !== "guest") {
                this.subscribePlayerUpdate();
                this.subscribeTourDisplayUpdate();
            }

            if (info.activeUnivers) {
                this.activeUnivers = info.activeUnivers;
            }

            if (info.tourOnDisplay) {
                this.tourOnDisplay = info.tourOnDisplay;
            }
        }

        switch (info.data.action) {
            case "guestConnected":
                this.connectedRemote = info.data.data;
                break;

            case "guestDisconnected":
                this.connectedRemote = info.data.data;
                break;

            case "playerChange":
                this.videoIsOnPlay = info.data.data;
                break;

            case "universChange":
                this.activeUnivers = info.data.data;
                break;

            case "displayTour":
                this.tourOnDisplay = info.data.data;
                break;

            case "navigate":
                const univers = info.data.data;
                this.navigationService.goToUnivers(univers);

                break;

            case "selectSuitcase":
                const suitcaseSize = info.data.data;
                this.suitcaseService.setSuitcase(suitcaseSize);
                this.navigationService.goToDiscover();

                break;

            case "selectBubble":
                const bubble = info.data.data;
                this.bubbleService.selectBubble(bubble);
                break;

            case "clearBubbles":
                this.bubbleService.resetBubbles();
                break;

            case "selectContinent":
                const continent = info.data.data;
                let oqpContinent: any = document.getElementsByTagName("mv-oqp");

                if (oqpContinent) {
                    oqpContinent = oqpContinent[0].shadowRoot;
                    const continentElement = oqpContinent
                        .getElementById("oqp")
                        .getElementsByClassName("oqp__where--item")[continent.id - 1];
                    continentElement?.click();
                }

                break;

            case "selectMonth":
                const month = info.data.data;
                let oqpMonth: any = document.getElementsByTagName("mv-oqp");

                if (oqpMonth) {
                    oqpMonth = oqpMonth[0].shadowRoot;
                    const monthElement = oqpMonth.getElementById("oqp").getElementsByClassName("oqp__when--item")[
                        month.id - 1
                    ];
                    monthElement?.click();
                }

                break;

            case "previous-tour":
                if (this.currentTour - 1 >= 0) {
                    this.currentTour--;
                } else {
                    this.currentTour = this.getTotalTour() - 1;
                }
                this.hightlightTour();

                break;

            case "select-tour":
                this.hightlightTour();
                setTimeout(() => {
                    this.selectTour();
                }, 500);

                break;

            case "next-tour":
                if (this.currentTour + 1 >= this.getTotalTour()) {
                    this.currentTour = 0;
                } else {
                    this.currentTour++;
                }
                this.hightlightTour();

                break;

            case "closeTour":
                const closeBtn: any = document.getElementsByTagName("walrus-trip");
                if (closeBtn) {
                    closeBtn[0].querySelector("walrus-button").click();
                }

                break;

            case "tab-map":
            case "tab-itinerary":
            case "tab-activities":
            case "tab-hotels":
            case "tab-faq":
            case "tab-suggestions":
                const tourTabs: any = document.getElementsByTagName("mv-tour");
                if (tourTabs) {
                    tourTabs[0].shadowRoot.getElementById(info.data.action).click();
                }

                break;

            case "diapo-prev":
                const tourDiapoPrev: any = document.getElementsByTagName("mv-tour");
                if (tourDiapoPrev) {
                    if (tourDiapoPrev[0].shadowRoot.querySelector("#tab-itinerary.active")) {
                        tourDiapoPrev[0].shadowRoot
                            .querySelector(
                                ".program__itinerary__day.active .program__itinerary__day--selector--previous.btn",
                            )
                            .click();
                    }
                }
                break;

            case "diapo-next":
                const tourDiapoNext: any = document.getElementsByTagName("mv-tour");
                if (tourDiapoNext) {
                    if (tourDiapoNext[0].shadowRoot.querySelector("#tab-itinerary.active")) {
                        tourDiapoNext[0].shadowRoot
                            .querySelector(
                                ".program__itinerary__day.active .program__itinerary__day--selector--next.btn",
                            )
                            .click();
                    }
                }
                break;

            case "diapo-start":
                const tourDiapoStart: any = document.getElementsByTagName("mv-tour");
                if (tourDiapoStart) {
                    if (tourDiapoStart[0].shadowRoot.querySelector("#tab-itinerary.active")) {
                        tourDiapoStart[0].shadowRoot
                            .querySelector("#diaporama-state .diaporama--btn.play span.play")
                            .click();
                    }
                }
                break;

            case "diapo-pause":
                const tourDiapoPause: any = document.getElementsByTagName("mv-tour");
                if (tourDiapoPause) {
                    if (tourDiapoPause[0].shadowRoot.querySelector("#tab-itinerary.active")) {
                        tourDiapoPause[0].shadowRoot
                            .querySelector("#diaporama-state .diaporama--btn.play span.pause")
                            .click();
                    }
                }
                break;

            case "diapo-stop":
                const tourDiapoStop: any = document.getElementsByTagName("mv-tour");
                if (tourDiapoStop) {
                    if (tourDiapoStop[0].shadowRoot.querySelector("#tab-itinerary.active")) {
                        tourDiapoStop[0].shadowRoot.querySelector("#diaporama-state .diaporama--btn.stop").click();
                    }
                }
                break;

            case "selectVideotag":
                const tag = info.data.data;
                const videoTag = document.getElementById(`walrus-filter-${tag.id}`);
                videoTag?.click();

                break;

            case "selectVideo":
                const video = info.data.data;
                this.videoService.playVideo(video);

                break;

            case "previous":
                this.playerService.playPrevious();

                break;

            case "fullscreen":
                this.playerService.toggleFullscreen();

                break;

            case "next":
                this.playerService.playNext();

                break;

            case "stop":
                this.playerService.hide();

                break;

            case "pause":
                this.playerService.pauseVideo();

                break;

            case "play":
                this.playerService.playVideo();

                break;

            default:
                break;
        }
    }

    protected getTotalTour() {
        let tourElement = 0;
        let oqpElement: any = document.getElementsByTagName("mv-oqp");

        if (oqpElement) {
            oqpElement = oqpElement[0].shadowRoot;
            tourElement = oqpElement.getElementById("oqp").getElementsByTagName("mv-oqp-tour").length;
        }

        return tourElement;
    }

    protected hightlightTour() {
        let oqpElement: any = document.getElementsByTagName("mv-oqp");

        if (oqpElement) {
            oqpElement = oqpElement[0].shadowRoot;
            const tourElement = oqpElement.getElementById("oqp").getElementsByTagName("mv-oqp-tour")[
                this.currentTour
            ].shadowRoot;
            console.log(`tour element`);
            console.log(tourElement);

            // scroll to tourElement only vertically
            tourElement.querySelector("div").scrollIntoView({ behavior: "smooth", block: "start" });

            // blink here in vanilla to keep a custom action on that element
            tourElement.querySelector("div").style.opacity = "0.1";
            setTimeout(() => {
                setTimeout(() => {
                    tourElement.querySelector("div").style.opacity = "1";
                }, 250);
            }, 250);
        }
    }

    protected selectTour() {
        let oqpElement: any = document.getElementsByTagName("mv-oqp");

        if (oqpElement) {
            oqpElement = oqpElement[0].shadowRoot;
            const tourElement = oqpElement.getElementById("oqp").getElementsByTagName("mv-oqp-tour")[
                this.currentTour
            ].shadowRoot;
            tourElement.querySelector("div").click();
        }
    }

    protected errReceived(err: any) {
        // Called if at any point WebSocket API signals some kind of error.
        this.connectedRemote = 0;
        this.clientIdSource.next(0);
        console.log("error happened");
        console.log(err);
        if (this.attemptToConnect < 3) {
            console.log("retry connection");
            this.attemptToConnect++;
            this.connect(this.mainOrigin);
        }
    }

    protected connectionClose() {
        // Called when connection is closed (for whatever reason).
        console.log("complete");
    }
}

export enum IDriverCursorDirection {
    HORIZONTAL = "horizontal",
    VERTICAL = "vertical",
}

export interface IDriverSpotlight {
    diameter: string;
    x: string;
    y: string;
}

export interface IDriverToast {
    color: string;
    duration: number | null;
    text: string;
    talignment: string;
    width: string;
    x: string;
    y: string;
}

export interface IDriverCursor {
    animated: boolean;
    color: string;
    direction: IDriverCursorDirection;
    from: string;
    rotation: string;
    to: string;
    x: string;
    y: string;
    speed: string;
}

export interface IDriverStep {
    actionStart?: any | null;
    actionEnd?: any | null;
    cursor: IDriverCursor | null;
    spotlight: IDriverSpotlight;
    toast: IDriverToast | null;
}

export interface IDriverStepGroup {
    displayed: boolean;
    reference: string;
    steps: IDriverStep[];
}

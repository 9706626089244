import { Theme } from "@ui/ui-oqp/src/models";

export enum BubbleType {
    CULTURE = "culture",
    DETENTE = "detente",
    FAMILY = "family",
    FOODING = "fooding",
    NATURE = "nature",
    SPORT = "sport",
}

export const mapBubbleToLabel = (bubbleType: BubbleType): string => {
    switch (bubbleType) {
        case BubbleType.CULTURE: {
            return "Culture & Patrimoine";
        }
        case BubbleType.DETENTE: {
            return "Détente & Bien-être";
        }
        case BubbleType.FAMILY: {
            return "Famille & Tribu";
        }
        case BubbleType.FOODING: {
            return "Fooding & Oenologie";
        }
        case BubbleType.NATURE: {
            return "Nature & Vie sauvage";
        }
        case BubbleType.SPORT:
        default: {
            return "Sport & Aventure";
        }
    }
};

export interface IContainer {
    x: number;
    y: number;
    height: number;
    width: number;
}

export interface IPosition {
    x: number;
    y: number;
}

// px per frame
export interface IVelocity {
    vx: number;
    vy: number;
}

export interface IBubble {
    icon: string;
    id: number;
    inside: boolean;
    isDragged: boolean;
    position: IPosition;
    type: BubbleType;
    velocity: IVelocity;
    velocityOrigin: IVelocity;
}

export const getInitialBubbles = (): IBubble[] => {
    return [
        ...getArray(4).map((index) => getBubble(index, BubbleType.CULTURE)),
        ...getArray(1).map((index) => getBubble(index, BubbleType.FAMILY)),
        ...getArray(3).map((index) => getBubble(index, BubbleType.FOODING)),
        ...getArray(2).map((index) => getBubble(index, BubbleType.NATURE)),
        ...getArray(2).map((index) => getBubble(index, BubbleType.SPORT)),
        ...getArray(2).map((index) => getBubble(index, BubbleType.DETENTE)),
    ].map((bubble, index) => ({ ...bubble, id: index }));
};

const getBubble = (index: number, type: BubbleType): IBubble => ({
    icon: `${type}_${index.toString().padStart(2, "0")}.svg`,
    id: 0,
    inside: false,
    isDragged: false,
    position: { x: 0, y: 0 },
    type,
    velocity: {
        vx: 0,
        vy: 0,
    },
    velocityOrigin: {
        vx: 0,
        vy: 0,
    },
});

export const initBubblePosition = (bubble: IBubble, bubbleSize: number, container: IContainer): IBubble => ({
    ...bubble,
    position: {
        x: randomInteger(container.width - bubbleSize) + container.x + bubbleSize / 2,
        y: randomInteger(container.height - bubbleSize) + container.y + bubbleSize / 2,
    },
});

export const initBubbleVelocity = (bubble: IBubble, maxSpeed: number): IBubble => {
    const velocity = {
        vx: 1 + randomInteger(maxSpeed) * positiveOrNegative(),
        vy: 1 + randomInteger(maxSpeed) * positiveOrNegative(),
    };

    return {
        ...bubble,
        velocity,
        velocityOrigin: velocity,
    };
};

export const mapBubbleToTheme = (bubble: IBubble): Theme => {
    switch (bubble.type) {
        case BubbleType.CULTURE: {
            return Theme.CULTURE;
        }
        case BubbleType.DETENTE: {
            return Theme.DETENTE;
        }
        case BubbleType.FAMILY: {
            return Theme.FAMILY;
        }
        case BubbleType.FOODING: {
            return Theme.FOODING;
        }
        case BubbleType.NATURE: {
            return Theme.NATURE;
        }
        default:
        case BubbleType.SPORT: {
            return Theme.SPORT;
        }
    }
};

const getArray = (n: number): number[] => [...Array(n).keys()].map((value) => value + 1);
const randomInteger = (limit: number): number => Math.floor(Math.random() * limit);
const positiveOrNegative = (): -1 | 1 => (Math.random() > 0.5 ? 1 : -1);

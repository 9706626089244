import { CommonModule } from "@angular/common";
import { NgModule } from "@angular/core";

import {
    ButtonComponent,
    DriverComponent,
    FilterComponent,
    HeaderComponent,
    ModalComponent,
    VideoComponent,
} from "./components";

@NgModule({
    bootstrap: [],
    declarations: [ButtonComponent, DriverComponent, FilterComponent, HeaderComponent, ModalComponent, VideoComponent],
    exports: [ButtonComponent, DriverComponent, FilterComponent, HeaderComponent, ModalComponent, VideoComponent],
    imports: [CommonModule],
    providers: [],
})
export class SharedModule {}

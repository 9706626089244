import { AfterViewInit, Component, OnInit } from "@angular/core";
import { take } from "rxjs/operators";

import { TagRepository, VideoRepository } from "@api-binding";
import { DeviceService, TagService, VideoService } from "@models";

import { environment } from "src/environments";

@Component({
    selector: "mv-walrus",
    styleUrls: ["./app.component.scss"],
    templateUrl: "./app.component.html",
})
export class AppComponent implements AfterViewInit, OnInit {
    public debug: boolean = false;

    public constructor(
        public readonly tagService: TagService,
        public readonly videoService: VideoService,
        private readonly videoRepository: VideoRepository,
        private readonly tagRepository: TagRepository,
        private readonly deviceService: DeviceService,
    ) {}

    public ngOnInit(): void {
        this.getVideos();
        this.getTags();

        this.debug = !environment.production;
    }

    public ngAfterViewInit() {
        this.deviceService.setDevice({ height: window.innerHeight, width: window.innerWidth });

        // fix walrus resize
        document.body.style.zoom = "100%";
    }

    public getVideos() {
        this.videoRepository
            .getVideos()
            .pipe(take(1))
            .subscribe((videos) => {
                this.videoService.setVideos(videos);
                this.videoService.setFilteredVideos(videos);
            });
    }

    public getTags() {
        this.tagRepository
            .getTags()
            .pipe(take(1))
            .subscribe((tags) => this.tagService.setTags(tags));
    }
}

import { Component } from "@angular/core";

import { animate, style, transition, trigger } from "@angular/animations";
import { DriverService } from "@app/models/driver/driver.service";

@Component({
    selector: "driver",
    styleUrls: ["./driver.component.scss"],
    templateUrl: "./driver.component.html",
    animations: [
        trigger("fadeInOut", [
            transition(":enter", [style({ opacity: 0 }), animate("0.3s", style({ opacity: 1 }))]),
            transition(":leave", [style({ opacity: 1 }), animate("0.3s", style({ opacity: 0 }))]),
        ]),
    ],
})
export class DriverComponent {
    public constructor(public driverService: DriverService) {}

    public interactAction() {
        this.driverService.interactAction();
    }
}

import { Injectable, RendererFactory2 } from "@angular/core";
import { Router } from "@angular/router";

import { environment } from "@environment";

import { PlayerService } from "@app/core/player/player.service";
import { BubbleService } from "@models/bubble/bubble.service";
import { SessionService } from "@models/session/session.service";
import { SuitcaseService } from "@models/suitcase/suitcase.service";
import { VideoService } from "@models/video/video.service";
import { DriverService } from "../driver/driver.service";

@Injectable({ providedIn: "root" })
export class IdleService extends SessionService {
    // handle ui-kit screensaver
    private readonly screensaver: HTMLElement | any;

    public constructor(
        private readonly bubbleService: BubbleService,
        private readonly suitcaseService: SuitcaseService,
        public readonly rendererFactory2: RendererFactory2,
        private readonly videoService: VideoService,
        private readonly playerService: PlayerService,
        private readonly driverService: DriverService,
        private readonly router: Router,
    ) {
        super(rendererFactory2);

        // subscribe to player state play or stop video
        this.playerService.playerObsState$.subscribe((played) => {
            // if video is playing
            if (played) {
                this.blockBehavior = true;
            } else {
                this.blockBehavior = false;

                // if no session on going, just start one
                if (!this.isOnGoing && this.sessionNumber > 0) {
                    this.startSession();
                }
            }
        });

        this.playerService.actionScreensaver$.subscribe((action: boolean) => {
            if (action && this.playerService.screensaver) {
                this.startSession();
                this.reset();
            }
        });
    }

    // init from login page
    public initIdle() {
        this.startSession(environment.inatictivityTime);
        this.getEventSessionEnd().subscribe(() => {
            if (!this.blockBehavior) {
                this.showScreensaver();
            }
        });
    }

    public async reset() {
        this.bubbleService.resetBubbles();
        this.suitcaseService.resetSuitcase();
        this.videoService.clear();
        this.driverService.clear();

        await this.router.navigate(["/#/home"]);
    }

    public showScreensaver() {
        const video = this.videoService.getScreenSaverVideo();
        if (video) {
            this.videoService.playVideoScreensaver(video);
        } else {
            this.reset();
        }
    }
}

import { HttpClient } from "@angular/common/http";
import { Injectable } from "@angular/core";
import { Observable, of } from "rxjs";
import { catchError, map } from "rxjs/operators";

import { IVideoGetDto } from "@dtos";
import { environment } from "@environment";
import { IVideo } from "@models";
import { VideoMapper } from "./video.mapper";

@Injectable()
export class VideoRepository {
    public constructor(
        private readonly http: HttpClient,
        private readonly videoMapper: VideoMapper,
    ) {}

    // get all videos
    public getVideos(): Observable<IVideo[]> {
        const dataSource = this.http.get<IVideoGetDto[]>(`${environment.moustacheApiUrl}/videos`);

        return dataSource.pipe(
            map((videos) => videos.map(this.videoMapper.mapGetVideo)),
            catchError(() => of([])),
        );
    }
}

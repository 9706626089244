import { Injectable, OnDestroy, Renderer2, RendererFactory2 } from "@angular/core";
import { interval, Subject, Subscription } from "rxjs";
import { takeWhile } from "rxjs/operators";

@Injectable({ providedIn: "root" })
export class SessionService implements OnDestroy {
    public sessionEnd: Subject<boolean> = new Subject<boolean>();

    protected origin = `idle`;
    private lastInteraction: Date = new Date();
    private readonly subscriptions = new Subscription();
    private definedInactivityPeriod = 10000;

    public blockBehavior: boolean = false;
    public isOnGoing: boolean | null = null;
    public sessionNumber = 0;

    private readonly renderer: Renderer2;

    public constructor(public rendererFactory2: RendererFactory2) {
        this.renderer = this.rendererFactory2.createRenderer(null, null);
        // if any interaction computer or touch screen
        this.renderer.listen("document", "mousemove", () => {
            // console.log(`mouse interaction for ${this.origin}`);
            this.resetSession();
        });
    }

    // set screensaver launch time
    public setInactivityPeriod(min: number) {
        this.definedInactivityPeriod = min * 60 * 1000;
    }

    // start a session and subscribe to timer
    public startSession(inatictivityTime?: number) {
        if (this.sessionEnd.closed || this.sessionEnd == null) {
            this.sessionEnd = new Subject<boolean>();
            this.isOnGoing = true;
        }

        if (inatictivityTime) {
            this.setInactivityPeriod(inatictivityTime);
        }

        this.resetSession();
        this.subscriptions.add(this.idlePoll().subscribe());
        this.sessionNumber++;
    }

    // everytime there is interaction just reset timer
    public resetSession() {
        this.lastInteraction = new Date();
    }

    // timer manager
    public idlePoll() {
        return interval(1000).pipe(
            takeWhile(() => {
                // if timer reach show screensaver
                if (new Date().getTime() - this.lastInteraction.getTime() > this.definedInactivityPeriod) {
                    this.sessionEnd.next(true);
                    this.isOnGoing = false;
                }

                return new Date().getTime() - this.lastInteraction.getTime() < this.definedInactivityPeriod;
            }),
        );
    }

    public getEventSessionEnd() {
        return this.sessionEnd;
    }

    public ngOnDestroy() {
        this.subscriptions.unsubscribe();
    }
}

import { Injectable } from "@angular/core";
import { environment } from "@environment";
import { MoustacheOqpRepository } from "@moustache-bindings";

@Injectable()
export class OqpRepository extends MoustacheOqpRepository {
    public getMoustacheApiUrl(): string {
        return environment.moustacheApiUrl;
    }
}

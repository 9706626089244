import { CommonModule } from "@angular/common";
import { CUSTOM_ELEMENTS_SCHEMA, NgModule } from "@angular/core";
import { SharedModule } from "@shared/shared.module";

import { MapComponent } from "./map/map.component";
@NgModule({
    declarations: [MapComponent],
    exports: [MapComponent],
    imports: [CommonModule, SharedModule],
    schemas: [CUSTOM_ELEMENTS_SCHEMA],
})
export class MapModule {}

<div
    class="driver--backdrop"
    [@fadeInOut]
    *ngIf="(driverService.showDriver$ | async) === true && driverService.activeStep"
    (click)="interactAction()"
    [style.--spotlight-x]="driverService.activeStep.spotlight.x"
    [style.--spotlight-y]="driverService.activeStep.spotlight.y"
    [style.--spotlight-diameter]="driverService.activeStep.spotlight.diameter"
    [ngClass]="{
        hide: (driverService.showDriver$ | async) === false,
        show: (driverService.showDriver$ | async) === true
    }"
>
    <div
        *ngIf="driverService.activeStep.cursor"
        class="driver--cursor {{ driverService.activeStep.cursor.direction }}"
        [ngClass]="{ animated: driverService.activeStep.cursor.animated }"
        [style.--cursor-x]="driverService.activeStep.cursor.x"
        [style.--cursor-y]="driverService.activeStep.cursor.y"
        [style.--cursor-from]="driverService.activeStep.cursor.from"
        [style.--cursor-to]="driverService.activeStep.cursor.to"
        [style.--cursor-color]="driverService.activeStep.cursor.color"
        [style.--cursor-rotation]="driverService.activeStep.cursor.rotation"
        [style.--cursor-speed]="driverService.activeStep.cursor.speed"
    >
        <svg
            xmlns="http://www.w3.org/2000/svg"
            class="icon icon-tabler icon-tabler-hand-finger"
            viewBox="0 0 24 24"
            preserveAspectRatio="xMinYMin slice"
            stroke-width="2"
            stroke="currentColor"
            fill="none"
            stroke-linecap="round"
            stroke-linejoin="round"
        >
            <path stroke="none" d="M0 0h24v24H0z" fill="none"></path>
            <path d="M8 13v-8.5a1.5 1.5 0 0 1 3 0v7.5"></path>
            <path d="M11 11.5v-2a1.5 1.5 0 1 1 3 0v2.5"></path>
            <path d="M14 10.5a1.5 1.5 0 0 1 3 0v1.5"></path>
            <path
                d="M17 11.5a1.5 1.5 0 0 1 3 0v4.5a6 6 0 0 1 -6 6h-2h.208a6 6 0 0 1 -5.012 -2.7a69.74 69.74 0 0 1 -.196 -.3c-.312 -.479 -1.407 -2.388 -3.286 -5.728a1.5 1.5 0 0 1 .536 -2.022a1.867 1.867 0 0 1 2.28 .28l1.47 1.47"
            ></path>
        </svg>
    </div>

    <div
        *ngIf="driverService.activeStep.toast"
        class="driver--toast"
        [style.--toast-x]="driverService.activeStep.toast.x"
        [style.--toast-y]="driverService.activeStep.toast.y"
        [style.--toast-talignment]="driverService.activeStep.toast.talignment"
        [style.--toast-width]="driverService.activeStep.toast.width"
        [style.--toast-color]="driverService.activeStep.toast.color"
    >
        {{ driverService.activeStep.toast.text }}
    </div>
</div>

import { Component, TrackByFunction } from "@angular/core";

import { ActivatedRoute } from "@angular/router";

import { RemoteService } from "@core/remote/remote.service";
import { BubbleService, IBubble, ITag, IVideo, Suitcase, suitcaseSort, TagService, VideoService } from "@models";

import { AppRoutes } from "../../../routes";

@Component({
    selector: "walrus-remote-controller",
    styleUrls: ["./remote-controller.component.scss"],
    templateUrl: "./remote-controller.component.html",
})
export class RemoteControllerComponent {
    public clientId: number | null = null;

    public Suitcase = Suitcase;
    public suitcaseSort = suitcaseSort;

    public swiperOptions = {
        a11y: true,
        centeredSlides: true,
        direction: "horizontal",
        freeMode: false,
        freeModeMinimumVelocity: 0.08,
        keyboard: true,
        loop: false,
        loopAdditionalSlides: 20,
        loopedSlides: 10,
        mousewheel: false,
        navigation: false,
        pagination: false,
        scrollbar: false,
        slidesPerView: 1.125,
        spaceBetween: 80,
    };

    public continents = [
        { id: 1, name: `Amérique du Nord`, class: `mv-icons--destinations--north-america` },
        { id: 2, name: `Amérique Latine`, class: `mv-icons--destinations--latin-america` },
        { id: 3, name: `Afrique`, class: `mv-icons--destinations--africa` },
        { id: 4, name: `Asie`, class: `mv-icons--destinations--asia` },
        { id: 5, name: `Europe`, class: `mv-icons--destinations--europe` },
        { id: 6, name: `Océanie`, class: `mv-icons--destinations--pacific-islands` },
    ];

    public months = [
        { id: 1, name: `Janvier`, short_name: `Jan` },
        { id: 2, name: `Février`, short_name: `Fev` },
        { id: 3, name: `Mars`, short_name: `Mar` },
        { id: 4, name: `Avril`, short_name: `Avr` },
        { id: 5, name: `Mai`, short_name: `Mai` },
        { id: 6, name: `Juin`, short_name: `Juin` },
        { id: 7, name: `Juillet`, short_name: `Juil` },
        { id: 8, name: `Août`, short_name: `Août` },
        { id: 9, name: `Septembre`, short_name: `Sept` },
        { id: 10, name: `Octobre`, short_name: `Oct` },
        { id: 11, name: `Novembre`, short_name: `Nov` },
        { id: 12, name: `Décembre`, short_name: `Déc` },
    ];

    public hostId: string | undefined | null;

    constructor(
        private readonly route: ActivatedRoute,
        public readonly bubbleService: BubbleService,
        public readonly tagService: TagService,
        public readonly videoService: VideoService,
        public remoteService: RemoteService,
    ) {
        // get param "id" from url and assign it to hostId
        this.hostId = this.route.snapshot.queryParamMap.get("id");

        console.log("host Id is ", this.hostId);
        this.remoteService.connect("guest", this.hostId);
    }

    public trackById: TrackByFunction<ITag> = (index: number, item: ITag | IVideo): any => {
        return item.id;
    };

    public goToHome() {
        this.remoteService.sendAction(`navigate`, AppRoutes.HOME);
    }

    public goToMDiscover() {
        this.remoteService.sendAction(`navigate`, AppRoutes.DISCOVER);
    }

    public goToMultimedia() {
        this.remoteService.sendAction(`navigate`, AppRoutes.MULTIMDEIA);
    }

    // Home
    public async selectSuitcase(suitcase: any) {
        this.remoteService.sendAction(`selectSuitcase`, suitcase);
    }

    // Discover
    public async closeTour() {
        this.remoteService.sendAction(`closeTour`);
    }

    public async selectBubble(bubble: IBubble) {
        this.remoteService.sendAction(`selectBubble`, bubble);
    }

    public async clearBubbles() {
        this.remoteService.sendAction(`clearBubbles`);
    }

    public async selectContinent(continent: any) {
        this.remoteService.sendAction(`selectContinent`, continent);
    }

    public async selectMonth(month: any) {
        this.remoteService.sendAction(`selectMonth`, month);
    }

    // Multimedia
    public async selectVideotag(tag: ITag) {
        this.remoteService.sendAction(`selectVideotag`, tag);
    }

    public async selectVideo(video: IVideo) {
        this.remoteService.sendAction(`selectVideo`, video);
    }

    public triggerAction(action: string) {
        this.remoteService.sendAction(action);
    }
}

<div *ngIf="0 == (remoteService.clientId$ | async)" class="remote--controller--loading"></div>

<div *ngIf="0 != (remoteService.clientId$ | async)" class="remote--controller--container">
    <!-- <div *ngIf="remoteService.displayLoader" class="loading">
        <div class="msg">Merci de patentier...</div>
    </div> -->
    <div class="info">Remote player: {{ remoteService.getClientId() }}</div>
    <!-- Home -->
    <ng-container *ngIf="remoteService.activeUniversIsHome() && !remoteService.videoIsOnPlay">
        <div class="remote--controller--container__univers remote--controller--container__univers--home">
            <div class="title">Accueil</div>
            <div class="content">
                <div
                    class="btn"
                    *ngFor="let suitcase of Suitcase | keyvalue: suitcaseSort"
                    (click)="selectSuitcase(suitcase)"
                >
                    {{ suitcase.value }}
                </div>
                <div class="divider"></div>
                <div class="btn" (click)="goToMultimedia()">Voir d'autres vidéos</div>
            </div>
        </div>
    </ng-container>

    <!-- Discover -->
    <ng-container *ngIf="remoteService.activeUniversIsDiscover() && !remoteService.tourOnDisplay">
        <div class="remote--controller--container__univers remote--controller--container__univers--discover">
            <div class="title">
                <div class="bck" (click)="goToHome()">←</div>
                Découvrir
            </div>
            <div class="content">
                <ng-container *ngIf="bubbleService.bubbles$ | async as bubbles">
                    <div class="bubbles">
                        <ng-container *ngFor="let bubble of bubbles; let index = index">
                            <div class="bubble {{ bubble.type }}" (click)="selectBubble(bubble)">
                                <img class="bubble__icon" src="assets/bubbles/{{ bubble.icon }}" />
                            </div>
                        </ng-container>
                    </div>
                    <div class="btn" (click)="clearBubbles()">Vider ma valise</div>
                    <div class="divider"></div>
                </ng-container>
                <div class="continents">
                    <ng-container *ngFor="let continent of continents">
                        <div class="continent" (click)="selectContinent(continent)">
                            <i class="{{ continent.class }}"></i>
                        </div>
                    </ng-container>
                </div>
                <div class="divider"></div>
                <div class="months">
                    <ng-container *ngFor="let month of months">
                        <div class="month" (click)="selectMonth(month)">
                            <span>{{ month.short_name }}</span>
                        </div>
                    </ng-container>
                </div>
                <div class="divider"></div>
                <div class="cursor--elements">
                    <div class="cursor--element" (click)="triggerAction('previous-tour')"><span>←</span></div>
                    <div class="cursor--element select" (click)="triggerAction('select-tour')">
                        <span>Séléctionner</span>
                    </div>
                    <div class="cursor--element" (click)="triggerAction('next-tour')"><span>→</span></div>
                </div>
            </div>
        </div>
    </ng-container>

    <ng-container *ngIf="remoteService.activeUniversIsDiscover() && remoteService.tourOnDisplay">
        <div class="remote--controller--container__univers remote--controller--container__univers--discover">
            <div class="title">
                <div class="bck" (click)="closeTour()">←</div>
                Détail
            </div>
            <div class="content">
                <div class="btn" (click)="triggerAction('tab-map')">Carte</div>
                <div class="btn" (click)="triggerAction('tab-itinerary')">Programme</div>
                <div class="btn" (click)="triggerAction('tab-activities')">Activités</div>
                <div class="btn" (click)="triggerAction('tab-hotels')">Hôtels</div>
                <div class="btn" (click)="triggerAction('tab-faq')">Bon à savoir</div>
                <div class="btn" (click)="triggerAction('tab-suggestions')">Suggestions</div>
                <div class="divider"></div>
                <div class="btn" (click)="triggerAction('diapo-prev')">Jour précédent</div>
                <div class="btn" (click)="triggerAction('diapo-start')">Lancer le diapo</div>
                <div class="btn" (click)="triggerAction('diapo-pause')">Mettre en pause le diapo</div>
                <div class="btn" (click)="triggerAction('diapo-stop')">Quitter le diapo</div>
                <div class="btn" (click)="triggerAction('diapo-next')">Jour suivant</div>
            </div>
        </div>
    </ng-container>

    <!-- Multimedia -->
    <ng-container *ngIf="remoteService.activeUniversIsMultimedia() && !remoteService.videoIsOnPlay">
        <div class="remote--controller--container__univers remote--controller--container__univers--multimedia">
            <div class="title">
                <div class="bck" (click)="goToHome()">←</div>
                Multimédia
            </div>
            <div class="content">
                <ng-container *ngIf="tagService.tags$ | async as tags">
                    <ng-container *ngFor="let tag of tags; trackBy: trackById">
                        <ng-container *ngIf="tag.visibility === true">
                            <div class="btn" (click)="selectVideotag(tag)">{{ tag.title }}</div>
                        </ng-container>
                    </ng-container>
                    <div class="divider"></div>
                </ng-container>

                <ng-container *ngIf="videoService.filteredVideos$ | async as videos">
                    <div class="playlist">
                        <ng-container *ngFor="let video of videos; trackBy: trackById">
                            <div class="item" (click)="selectVideo(video)">
                                <div class="detail">{{ video.title }}</div>
                            </div>
                        </ng-container>
                    </div>
                </ng-container>
            </div>
        </div>
    </ng-container>

    <!-- Player commands -->
    <ng-container *ngIf="remoteService.videoIsOnPlay">
        <div class="remote--controller--container__univers remote--controller--container__univers--player">
            <div class="title">
                <div class="bck" (click)="triggerAction('stop')">←</div>
                Télécommande
            </div>
            <div class="content">
                <div class="btn" (click)="triggerAction('fullscreen')">Plein écran</div>
                <div class="btn" (click)="triggerAction('previous')">Vidéo précédente</div>
                <div class="btn" (click)="triggerAction('pause')">Pause</div>
                <div class="btn" (click)="triggerAction('play')">Lecture</div>
                <div class="btn" (click)="triggerAction('next')">Vidéo suivante</div>
            </div>
        </div>
    </ng-container>
</div>

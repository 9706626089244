import { Component, Input } from "@angular/core";

@Component({
    selector: "walrus-button",
    styleUrls: ["./button.component.scss"],
    templateUrl: "./button.component.html",
})
export class ButtonComponent {
    @Input() public type = `classic`;
    @Input() public title: string;
    @Input() public secondaryColor: boolean = false;
}

import { Injectable } from "@angular/core";
import { BehaviorSubject, Observable } from "rxjs";
import { getSuitecaseDuration, ISuitcaseDuration, Suitcase } from "./suitcase";

@Injectable({
    providedIn: "root",
})
export class SuitcaseService {
    public suitcase$: Observable<Suitcase | null>;
    public suitcaseDurationMax$: Observable<number | undefined>;
    public suitcaseDurationMin$: Observable<number | undefined>;
    public shouldReset$: Observable<boolean>;
    private readonly suitcaseSource = new BehaviorSubject<Suitcase | null>(null);
    private readonly suitcaseDurationMaxSource = new BehaviorSubject<number | undefined>(undefined);
    private readonly suitcaseDurationMinSource = new BehaviorSubject<number | undefined>(undefined);
    private readonly shouldResetSource = new BehaviorSubject<boolean>(false);

    public constructor() {
        this.suitcase$ = this.suitcaseSource.asObservable();
        this.suitcaseDurationMin$ = this.suitcaseDurationMinSource.asObservable();
        this.suitcaseDurationMax$ = this.suitcaseDurationMaxSource.asObservable();
        this.shouldReset$ = this.shouldResetSource.asObservable();
    }

    public setSuitcase(suitcase: Suitcase) {
        console.log("setSuitcase");
        console.log(suitcase);

        let shouldReset = false;

        if (JSON.stringify(this.suitcaseSource.value) !== JSON.stringify(suitcase)) {
            shouldReset = true;
            this.suitcaseSource.next(suitcase);
            const duration = getSuitecaseDuration(suitcase);

            if (null != duration) {
                this.suitcaseDurationMaxSource.next(duration.max);
                this.suitcaseDurationMinSource.next(duration.min);
            }
        }

        this.shouldResetSource.next(shouldReset);
    }

    public resetSuitcase() {
        this.suitcaseSource.next(null);
    }
}

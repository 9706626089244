import { Injectable } from "@angular/core";
import { environment } from "@environment";
import { MoustacheTourRepository } from "@moustache-bindings";

@Injectable()
export class TourRepository extends MoustacheTourRepository {
    public getMoustacheApiUrl(): string {
        return environment.moustacheApiUrl;
    }

    public getAssetsUrl(): string {
        return environment.siteAssetsUrl;
    }
}

<div
    class="video-thumbnail"
    [ngClass]="size === 'small' ? 'video-thumbnail--small' : ''"
    [ngStyle]="{ background: 'url(' + thumbnailPath + item.thumbnail + ')' }"
>
    <div class="video-thumbnail-trigger" id="{{ item.id }}"></div>
    <ng-container *ngIf="current$ | async as currentPlayerVideo">
        <div *ngIf="currentPlayerVideo && currentPlayerVideo.id === item.id" class="overlay-play">
            <span>Lecture en cours...</span>
        </div>
    </ng-container>
    <div *ngIf="shouldDisplayPlay === false && item.watched === true" class="overlay-play">
        <span>Déjà visionnée</span>
    </div>
    <ng-container *ngFor="let tag of item.tags; trackBy: trackById">
        <div *ngIf="tag.visibility === true" class="tag">{{ tag.shortTitle }}</div>
    </ng-container>
    <div class="play">
        <i class="mv-icons--simple--player"></i>
    </div>
    <div class="time">{{ item.duration }}</div>
    <div class="desc">
        <div class="title">
            {{ item.title }}
        </div>
        <div class="subtitle">{{ item.description }}</div>
    </div>
</div>
<ng-container *ngIf="size === 'large'">
    <div class="video-description">
        {{ item.description }}
    </div>
</ng-container>

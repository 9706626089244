import { Injectable } from "@angular/core";
import { BehaviorSubject, Observable } from "rxjs";

import { ITour } from "@ui/ui-oqp-tour/src/tour";
import { ITourProduct } from "@ui/ui-tour/src/tour-product";

import { OqpRepository, TourRepository } from "@api-binding";

@Injectable({
    providedIn: "root",
})
export class TourService {
    public currentTour$: Observable<ITourProduct | null>;
    public similarTours$: Observable<ITour[]>;

    private readonly currentTourSource = new BehaviorSubject<ITourProduct | null>(null);
    private readonly similarToursSource = new BehaviorSubject<ITour[]>([]);

    public constructor(
        private readonly tourRepository: TourRepository,
        private readonly oqpRepository: OqpRepository,
    ) {
        this.currentTour$ = this.currentTourSource.asObservable();
        this.similarTours$ = this.similarToursSource.asObservable();
    }

    public async setCurrentTourFromId(id: number): Promise<void> {
        const tour = await this.tourRepository.getTourProduct(id).toPromise();

        if (null != tour) {
            this.setCurrentTour(tour);
        }
    }

    public getCurrentTour(): ITourProduct | null {
        return this.currentTourSource.getValue();
    }

    public setCurrentTour(tour: ITourProduct | null): void {
        this.currentTourSource.next(tour);
    }

    public setSimilarTours(tours: ITour[]): void {
        this.similarToursSource.next(tours);
    }
}

interface IRandomToursParams {
    exceptTourId: number;
    nbTours: number;
    tours: ITour[];
}
// When selecting a product: show similar products by selecting (nbTours) random tours form the (tours) array.
// Exclude the selected product (exceptTourId).
export const getRandomTours = ({ tours: inputTours, nbTours, exceptTourId }: IRandomToursParams): ITour[] => {
    const tours = [...inputTours].filter((tour) => tour.nid !== exceptTourId);
    if (nbTours >= tours.length) {
        return tours;
    }

    return Array.from(Array(nbTours).keys()).map(() => tours.splice(Math.floor(Math.random() * tours.length), 1)[0]);
};

import { isTour, ITour } from "../../../ui-oqp-tour/src/tour";

export interface ITours {
    coup: ITour[];
    topAvis: ITour[];
}

export const isTours = (value: { [key: string]: any }): value is ITours =>
    Array.isArray(value.coup) &&
    Array.isArray(value.topAvis) &&
    !value.coup.some((coup) => !isTour(coup)) &&
    !value.topAvis.some((topAvis) => !isTour(topAvis));

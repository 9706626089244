import { HTTP_INTERCEPTORS, HttpClientModule } from "@angular/common/http";
import { NgModule } from "@angular/core";

import { OqpRepository, TagMapper, TagRepository, TourRepository, VideoMapper, VideoRepository } from "./index";
import { ErrorInterceptor } from "./interceptors/error.interceptor";

@NgModule({
    imports: [HttpClientModule],
    providers: [
        OqpRepository,
        TagMapper,
        TagRepository,
        TourRepository,
        VideoMapper,
        VideoRepository,
        { provide: HTTP_INTERCEPTORS, useClass: ErrorInterceptor, multi: true },
    ],
})
export class ApiBindingModule {}

import { Injectable } from "@angular/core";
import { BehaviorSubject, Observable } from "rxjs";

import { getInitialBubbles, IBubble, IContainer, initBubblePosition, initBubbleVelocity } from "./bubble";

const NB_MAX_BUBBLES = 6;

@Injectable({
    providedIn: "root",
})
export class BubbleService {
    // VELOCITY : px per second
    public static BUBBLE_MAX_VELOCITY = 30;
    // INTERACT VELOCITY : according to manual tests, it ranges from 1000 to 40000, around 2000 for an average throw
    public static INTERACT_MAX_VELOCITY = 2000;
    public bubbles$: Observable<IBubble[]>;
    public selectedBubbles$: Observable<IBubble[]>;
    public nbMaxBubbles = NB_MAX_BUBBLES;
    private readonly bubblesSource = new BehaviorSubject<IBubble[]>(getInitialBubbles());
    private readonly selectedBubblesSource = new BehaviorSubject<IBubble[]>([]);
    private originalBubbles: IBubble[];
    private originalContainers: IContainer[];

    public constructor() {
        this.bubbles$ = this.bubblesSource.asObservable();
        this.selectedBubbles$ = this.selectedBubblesSource.asObservable();
    }

    public initBubbles(bubbleSize: number, container: IContainer, maxSpeed: number) {
        const containers = cutContainer(container, this.bubblesSource.value.length);
        const newBubbles = this.bubblesSource.value.map((bubble, index) =>
            initBubbleVelocity(initBubblePosition(bubble, bubbleSize, containers[index]), maxSpeed),
        );
        this.bubblesSource.next(newBubbles);

        if (null == this.originalBubbles) {
            this.originalBubbles = newBubbles;
            this.originalContainers = containers;
        }
    }

    public selectBubble(bubble: IBubble) {
        if (this.nbMaxBubbles > this.selectedBubblesSource.value.length) {
            // do not add if we allready have max bubbles
            // Dirty hack, drop event seems to be fired multiples times ...
            const currentSelectedBubbles = this.selectedBubblesSource.value.map((item) => item.id);

            if (!currentSelectedBubbles.includes(bubble.id)) {
                this.toggleBubbleSelection(bubble);
            }
        }
    }

    public resetBubbles(): void {
        // reset selected bubbles position
        const selectedBubbles = this.selectedBubblesSource.value.map((bubble) => this.randomizeBubblePosition(bubble));

        this.bubblesSource.next([...this.bubblesSource.value, ...selectedBubbles]);
        this.selectedBubblesSource.next([]);
    }

    public removeBubble(bubble: IBubble): void {
        // reset bubble position
        const newBubble = this.randomizeBubblePosition(bubble);

        this.toggleBubbleSelection(newBubble);
    }

    private toggleBubbleSelection(bubble: IBubble): void {
        const isSelected = this.selectedBubblesSource.value.find((source) => source.id === bubble.id) != null;
        let newBubbles: IBubble[];
        let selectedBubbles: IBubble[];

        if (isSelected) {
            newBubbles = [...this.bubblesSource.value, bubble];
            selectedBubbles = this.selectedBubblesSource.value.filter((selected) => selected.id !== bubble.id);
        } else {
            newBubbles = this.bubblesSource.value.filter((bubbleSource) => bubbleSource.id !== bubble.id);
            selectedBubbles = [...this.selectedBubblesSource.value, bubble];
        }

        this.bubblesSource.next([...newBubbles]);
        this.selectedBubblesSource.next([...selectedBubbles]);
    }

    private randomizeBubblePosition(bubble: IBubble): IBubble {
        const randomContainer =
            Math.floor(Math.random() * (0 - this.originalContainers.length + 1)) + this.originalContainers.length;

        return initBubbleVelocity(
            initBubblePosition(bubble, this.bubblesSource.value.length + 1, this.originalContainers[randomContainer]),
            BubbleService.BUBBLE_MAX_VELOCITY,
        );
    }
}

const cutContainer = (container: IContainer, nbCompartiments: number): IContainer[] => {
    const nbCuts = Math.ceil(Math.sqrt(nbCompartiments));
    let currentX = 0;
    let currentY = 0;

    return [...Array(nbCompartiments).keys()].map(() => {
        const compartiment = {
            height: container.height / nbCuts,
            width: container.width / nbCuts,
            x: (currentX * container.width) / nbCuts,
            y: (currentY * container.height) / nbCuts,
        };

        currentX++;
        if (currentX >= nbCuts) {
            currentX = 0;
            currentY++;
        }

        return compartiment;
    });
};

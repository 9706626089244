import { Component, TrackByFunction } from "@angular/core";

import { PlayerService } from "../player.service";

import { IVideo } from "@models";

@Component({
    selector: "fumanbrush-player-details",
    styleUrls: ["./player-details.component.scss"],
    templateUrl: "./player-details.component.html",
})
export class PlayerDetailsComponent {
    public currentVideo: IVideo | null;

    public constructor(public readonly playerService: PlayerService) {
        this.playerService.currentVideo$.subscribe((video) => {
            this.currentVideo = video;
        });
    }

    public toggleDetails() {
        this.playerService.toggleDetails();
    }

    public trackById: TrackByFunction<IVideo> = (index: number, video: IVideo): any => {
        return video.id;
    };
}

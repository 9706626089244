import { CommonModule } from "@angular/common";
import { NgModule } from "@angular/core";

import { ApiBindingModule } from "@api-binding/api-binding.module";
import { PlayerModule } from "@core/player/player.module";
import { RemoteModule } from "@core/remote/remote.module";
import { MapModule } from "./map/map.module";

@NgModule({
    declarations: [],
    exports: [PlayerModule, RemoteModule, MapModule],
    imports: [CommonModule, ApiBindingModule, PlayerModule, RemoteModule, MapModule],
})
export class CoreModule {}

export interface ITour {
    alias: string;
    cities?: string[];
    co2: number | null;
    countries: string[];
    flightDuration: number | null;
    imgUrls: string[];
    nbDays: number;
    nbNights: number;
    nbReviews?: number | null;
    nid: number;
    price: number;
    title: string;
    seen: boolean;
}

export interface IOqpTour {
    coup: ITour[];
    topAvis: ITour[];
}

export const isTour = (value: { [key: string]: any }): value is ITour =>
    value.alias != null &&
    value.countries != null &&
    value.imgUrls != null &&
    value.nbDays != null &&
    value.nbNights != null &&
    value.nid != null &&
    value.price != null &&
    value.title != null;

export const isSameTour = (t1: ITour | null, t2: ITour | null): boolean => {
    if (t1 == null || t2 == null) {
        return t1 === t2;
    }

    return t1.nid === t2.nid;
};

export const tourHasChanged = (t1: ITour | null, t2: ITour | null): boolean => !isSameTour(t1, t2);

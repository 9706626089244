import { Injectable } from "@angular/core";

import { IVideoGetDto } from "@dtos";
import { IVideo } from "@models";

@Injectable()
export class VideoMapper {
    public mapGetVideo = (videoDto: IVideoGetDto): IVideo => {
        return { ...videoDto };
    };
}

<div
    *ngIf="currentVideo != null"
    [ngClass]="{ hide: (playerService.showDetails$ | async) === false }"
    class="played__video--details"
    (click)="toggleDetails()"
>
    <div class="details__title">
        {{ currentVideo.title }}&nbsp;<span class="details__duration">({{ currentVideo.duration }}&nbsp;min)</span>
    </div>
    <ng-container *ngFor="let tag of currentVideo.tags; trackBy: trackById">
        <div *ngIf="tag.visibility === true" class="details__tag">{{ tag.title }}</div>
    </ng-container>
    <div class="details__description">
        {{ currentVideo.description }}
    </div>
</div>

import { CommonModule } from "@angular/common";
import { NgModule } from "@angular/core";

import { SharedModule } from "@shared/shared.module";
import { SwiperModule } from "ngx-swiper-wrapper";
import { RemoteControllerComponent } from "./remote-controller/remote-controller.component";
import { RemoteComponent } from "./remote/remote.component";

import { NgxQRCodeModule } from "@techiediaries/ngx-qrcode";

import { RemoteRoutingModule } from "./remote-routing.module";

@NgModule({
    declarations: [RemoteComponent, RemoteControllerComponent],
    exports: [RemoteComponent, RemoteControllerComponent],
    imports: [CommonModule, RemoteRoutingModule, SharedModule, NgxQRCodeModule, SwiperModule],
})
export class RemoteModule {}

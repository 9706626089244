import { EnvironmentType, IEnvironment } from "./environment.interface";

export const environment: IEnvironment = {
    brodcastHost: "wss://preprod.remote.moustache.marcovasco.fr",
    inatictivityTime: 2,
    driverInatictivityTime: 1,
    isDriverActivated: true,
    mapServerUrl: "https://preprod.maps.moustache.marcovasco.fr",
    mediaAssetsPath: "media",
    mediaAssetsPathOptimized: "webp/videos",
    moustacheApiUrl: "https://preprod.api.moustache.marcovasco.fr",
    production: false,
    remoteRoute: "https://preprod.walrus.moustache.marcovasco.fr/#/remote",
    siteAssetsUrl: "https://c0.marcovasco.fr",
    streamingVideoPath: "streaming",
    type: EnvironmentType.PREPROD,
};

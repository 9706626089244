export interface ITourPlaceGetDto {
    title: string;
    latitude: number;
    longitude: number;
}

export interface ITourActivityGetDto {
    description: string;
    imageUrl: string;
    places: ITourPlaceGetDto[];
    title: string;
}

const isTourActivityGetDto = (activityDto: { [key: string]: any }): activityDto is ITourActivityGetDto =>
    activityDto.description != null &&
    activityDto.imageUrl != null &&
    Array.isArray(activityDto.places) &&
    activityDto.title != null;

export interface ITourHotelGetDto {
    description: string;
    imageUrl: string;
    places: ITourPlaceGetDto[];
    title: string;
}

const isTourHotelGetDto = (hotelDto: { [key: string]: any }): hotelDto is ITourHotelGetDto =>
    hotelDto.description != null &&
    hotelDto.imageUrl != null &&
    Array.isArray(hotelDto.places) != null &&
    hotelDto.title != null;

export interface IInclusionGetDto {
    description: string;
    id: number;
}

const isInclusionGetDto = (inclusionDto: { [key: string]: any }): inclusionDto is IInclusionGetDto =>
    inclusionDto.description != null && inclusionDto.id != null;

export interface ITourStepGetDto {
    description: string;
    imagesUrl: string[];
    places: ITourPlaceGetDto[];
    title: string;
}

const isTourStepGetDto = (stepDto: { [key: string]: any }): stepDto is ITourStepGetDto =>
    stepDto.description != null &&
    Array.isArray(stepDto.imagesUrl) &&
    Array.isArray(stepDto.places) &&
    stepDto.title != null;

export interface ITourProductGetDto {
    activities: ITourActivityGetDto[];
    averageRank: number | null;
    country: string;
    goodToKnow: string;
    hotels: ITourHotelGetDto[];
    id: number;
    included: string[];
    nbDays: number;
    nbNights: number;
    notIncluded: string[];
    places: string;
    steps: ITourStepGetDto[];
    strongpoints: string[];
    title: string;
}

export const isTourProductGetDto = (data: { [key: string]: any }): data is ITourProductGetDto =>
    Array.isArray(data.activities) &&
    !data.activities.some((activity) => activity == null || !isTourActivityGetDto(activity)) &&
    Array.isArray(data.hotels) &&
    !data.hotels.some((hotel) => hotel == null || !isTourHotelGetDto(hotel)) &&
    Array.isArray(data.included) &&
    !data.included.some((inclusion) => inclusion == null || !isInclusionGetDto(inclusion)) &&
    Array.isArray(data.notIncluded) &&
    !data.notIncluded.some((inclusion) => inclusion == null || !isInclusionGetDto(inclusion)) &&
    Array.isArray(data.steps) &&
    !data.steps.some((step) => step == null || !isTourStepGetDto(step));

import { from, Observable } from "rxjs";
import { map, tap } from "rxjs/operators";

import { isTourProductGetDto } from "@dtos";
import { ITourProduct } from "@ui/ui-tour/src/tour-product";
import { MoustacheTourMapper } from "./tour.mapper";

export abstract class MoustacheTourRepository {
    private readonly tourMapper: MoustacheTourMapper;

    public constructor() {
        this.tourMapper = new MoustacheTourMapper(this.getAssetsUrl());
    }

    public getTourProduct(id: number): Observable<ITourProduct> {
        const dataSource = from(fetch(`${this.getMoustacheApiUrl()}/tours/${id}`).then((response) => response.json()));

        return dataSource.pipe(
            tap((tourDto) => {
                if (tourDto == null || !isTourProductGetDto(tourDto)) {
                    throw new Error(`MoustacheTourRepository - getTour : tourDto has an unexpected format`);
                }
            }),
            map(this.tourMapper.mapGetTourProduct),
        );
    }

    public abstract getMoustacheApiUrl(): string;
    public abstract getAssetsUrl(): string;
}

import { NgModule } from "@angular/core";
import { RouterModule, Routes } from "@angular/router";

import { RemoteControllerComponent } from "./remote-controller/remote-controller.component";

const routes: Routes = [
    {
        component: RemoteControllerComponent,
        path: "",
    },
];

@NgModule({
    exports: [RouterModule],
    imports: [RouterModule.forChild(routes)],
})
export class RemoteRoutingModule {}

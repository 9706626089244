import { HttpClient } from "@angular/common/http";
import { Injectable } from "@angular/core";
import { Observable, of } from "rxjs";
import { catchError, map } from "rxjs/operators";

import { ITagGetDto } from "@dtos";
import { environment } from "@environment";
import { ITag } from "@models";
import { TagMapper } from "./tag.mapper";

@Injectable()
export class TagRepository {
    public constructor(
        private readonly http: HttpClient,
        private readonly tagMapper: TagMapper,
    ) {}

    // get all tags
    public getTags(): Observable<ITag[]> {
        const dataSource = this.http.get<ITagGetDto[]>(`${environment.moustacheApiUrl}/videos/tags`);

        return dataSource.pipe(
            map((tags) => tags.map(this.tagMapper.mapGetTag)),
            catchError(() => of([])),
        );
    }
}

import { Injectable } from "@angular/core";

import { Router } from "@angular/router";
import { AppRoutes } from "@app/routes";

@Injectable({
    providedIn: "root",
})
export class NavigationService {
    public constructor(private readonly router: Router) {}

    public getCurrentRoute(): string {
        return this.router.url;
    }

    public getCurrentSection(): string {
        const currentRoute = this.getCurrentRoute();
        const currentSection = currentRoute.split("/")[1];

        return currentSection;
    }

    public goToUnivers(univers: string) {
        switch (univers) {
            case AppRoutes.HOME:
                this.goToHome();
                break;
            case AppRoutes.DISCOVER:
                this.goToDiscover();
                break;
            case AppRoutes.MULTIMDEIA:
                this.goToMultimedia();
                break;
            default:
                console.error(`Univers - routing error : unknown univers ${univers}`);
        }
    }

    public goToDiscover() {
        this.router.navigate([AppRoutes.DISCOVER]).catch((error) => {
            console.error(`Discover - routing error : ${error.message}`);
        });
    }

    public goToMultimedia() {
        this.router.navigate([AppRoutes.MULTIMDEIA]).catch((error) => {
            console.error(`Multiemdia - routing error : ${error.message}`);
        });
    }

    public goToHome() {
        this.router.navigate([AppRoutes.HOME]).catch((error) => {
            console.error(`Home - routing error : ${error.message}`);
        });
    }
}

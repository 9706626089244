import { from, Observable } from "rxjs";
import { map, tap } from "rxjs/operators";

import { IOqpParamDto } from "@dtos";
import { IOqpConfig } from "@ui/ui-oqp/src/models/oqp-event";
import { isTours, ITours } from "@ui/ui-oqp/src/models/tours";
import { mapGetOqp } from "./oqp.mapper";

export abstract class MoustacheOqpRepository {
    public getTours(oqpConfig: IOqpConfig): Observable<ITours> {
        const params: IOqpParamDto = {
            continentId: oqpConfig.continent.id,
            monthId: oqpConfig.month.id,
            themeIds: oqpConfig.themes.map((theme) => theme.id),
        };

        // TODO:: I'm trying this because of pipeline errors
        if (oqpConfig.max != null) {
            params.max = oqpConfig.max;
        }
        if (oqpConfig.min != null) {
            params.min = oqpConfig.min;
        }

        const urlParams = new URLSearchParams({
            continentId: params.continentId.toString(),
            monthId: params.monthId.toString(),
        });

        if (params.themeIds.length > 0) {
            urlParams.append("themeIds", params.themeIds.toString());
        }

        if (params.min != null) {
            urlParams.append("min", params.min.toString());
        }

        if (params.max != null) {
            urlParams.append("max", params.max.toString());
        }

        const dataSource = from(
            fetch(`${this.getMoustacheApiUrl()}/oqp?${urlParams.toString()}`).then((response) => response.json()),
        );

        return dataSource.pipe(
            tap((tours) => {
                if (tours == null || !isTours(tours)) {
                    const error = isError(tours) ? `: ${tours.message}` : "";
                    throw new Error(`OqpRepository - return from getOqp is not a tours${error}`);
                }
            }),
            map(mapGetOqp),
        );
    }

    public abstract getMoustacheApiUrl(): string;
}

const isError = (error: { [key: string]: any }): error is Error => error.message != null;

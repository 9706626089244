import { IContinent } from "./continent";
import { IMonth, isMonth } from "./month";
import { isTheme, ITheme } from "./theme";

export interface IOqpConfig {
    continent: IContinent;
    max?: number | null;
    min?: number | null;
    month: IMonth;
    themes: ITheme[];
}

export class OqpEvent extends CustomEvent<{ oqp: IOqpConfig }> {
    public constructor(config: { oqp: IOqpConfig }) {
        super("oqp", { detail: config });
    }
}

export const isOqpEvent = (event: Event & { detail: { oqp: { [key: string]: any } } }): event is OqpEvent => {
    if (event == null || event.detail == null || event.detail.oqp == null) {
        return false;
    }

    if (event.detail.oqp.continent == null || event.detail.oqp.month == null || event.detail.oqp.themes == null) {
        return false;
    }

    const { continent, month, themes } = event.detail.oqp;

    return (
        month != null &&
        isMonth(month) &&
        Array.isArray(themes) &&
        !themes.some((theme) => theme == null || !isTheme(theme))
    );
};
